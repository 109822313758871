/**
 * 模块名称: 信用账户统计厂商Id查询
 * @author james
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Button,
  // message,
} from 'antd'

const { Column } = Table
let pageSize = 10
let currentPage = 1
let filter = {
  venderId: '',
}

const CreditVenderIdQuery = (props) => {
  const { setTransferVisible, sproposerId, companyId, accountType, clientId} = props
  const [vendorList, setVendorList] = useState([])
  const [count, setCount] = useState(0)
  useEffect(() => {
    filter = {
      venderId: '',
    }
    api.getCreditVenderList({ clientId: clientId, userId: sproposerId, companyId: companyId, creditType: accountType, limit: pageSize, page: currentPage })
      .then(data => {
        // setVendorLoading(false)
        setCount(data.count)
        setVendorList(data.list)
      })
      .catch()
    // getVendorList()
  }, [])


  const getVendorList = () => {
    // setVendorLoading(true)
    api.getCreditVenderList({ clientId: clientId, vendor: filter.venderId, userId: sproposerId, companyId: companyId, creditType: accountType, limit: pageSize, page: currentPage })
      .then(data => {
        // setVendorLoading(false)
        setVendorList(data.list)
        setCount(data.count)
      })
      .catch()
  }

  const departInp = useRef(null)
  const onSearch = () => {
    filter.venderId = departInp.current.value
    // if (departInp.current.value == '') {
    //   message.info("请输入厂商ID")
    //   return
    // }
    getVendorList()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    getVendorList()
  }

  return (
    <>
      <div className="vendorstry">
        <div className="vendorwrap">
          <div className="vendor-item">
            <div style={{ width: 80 }}>厂商ID: </div>
            <input className="ant-input" ref={departInp} type="text" placeholder="请输入厂商ID " onKeyUp={onKeyup} />
          </div>
          <Button onClick={onSearch} type="primary">查询</Button>
        </div>
        <Table
          dataSource={vendorList}
          rowKey="id"
          className="table-page"
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="业务类型" dataIndex="businessName" />
          <Column title="订单编号" dataIndex="number" />
          <Column title="厂商ID" dataIndex="vendor" />
          <Column title="提单人" dataIndex="userName" />
          <Column title="申请时间" dataIndex="appliedate" />
        </Table>

        <div style={{ height: 60 }}></div>
        <div className="setVendorstryBtn">
          <Button type="primary" onClick={() => { setTransferVisible(false) }}>关闭</Button>
        </div>
      </div>
    </>
  )
}

export default CreditVenderIdQuery