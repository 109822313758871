/**
 * 模块名称: 公司信息管理
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Corp from './Corp'
import Department from './Department'
import Group from './Group'
import './assets/style.scss'

const CorpIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/group'} component={Group} />
      <Route path={match.path + '/department'} component={Department} />
      <Route component={Corp} />
    </Switch>
  )
}

export default CorpIndex