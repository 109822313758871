/**
 * 模块名称: 转户列表
 * @author James
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'

import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Modal,
  Alert,
  Tabs,
  Switch
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
  companyId: '',
  no: '',
  inId: '',
  outId: '',
  approvalStatus: '',
  auditStatus: '',
  status: '',
  sTime: '',
  eTime: '',
}
let params = {}

const AddCustomerAudit = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [auditStatusAll, setAuditStatusAll] = useState([]) // 审批状态
  const [visible, setVisible] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const search = parseSearch(props.location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      companyId: '',
      no: '',
      name: '',
      inId	: '',
      outId: '',
      approvalStatus: '',
      status: '',
      sTime: '',
      eTime: '',
      spTime:'',
      epTime:'',
    }
    shouldMe = null
    apiOperateTimer = true
    getList()
    getFilterData()
  }, [])

  const getList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(shouldMe){
        filter.shouldMe = shouldMe
      }else{
        for(let i in filter){
          if(i == 'shouldMe')delete filter[i]
        }
      }
      api.getTransferList({ ...filter, limit: pageSize, page: currentPage })
        .then(data => {
          setLoading(false)
          setList(data.list)
          setCount(data.count)
        })
        .catch(() => setLoading(false))
        apiOperateTimer = false
        setShouldMeDis(false)
        let timer = setTimeout(() => {
          apiOperateTimer = true
          setShouldMeDis(true)
          clearTimeout(timer)
        }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getList()
  }

  // 弹出框确定
  const HandleOk = () => {
    if (filterInfoValue.trim() === '') {
      message.error('筛选名称不能为空')
      return
    }
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }
  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }
  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          sTime: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          eTime: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : '',
          spTime: vals.passTime ? vals.passTime[0].format('YYYY-MM-DD') : '',
          epTime: vals.passTime ? vals.passTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    shouldMe = null
    filter = {
      companyId: filter.companyId,
      no: '',
      name: '',
      inId: '',
      outId: '',
      approvalStatus: '',
      status: filter.status,
      sTime: '',
      eTime: '',
      spTime: '',
      epTime: '',
    }
    setStatusVal(undefined)
    setStatusValName(undefined)
    history.replace(match.path)
    currentPage = 1
    getList()
  }
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }
  // 筛选条件
  const getFilterData = () => {
    api.getAuditStatusList({type: '18'}).then(data => {
      setAuditStatusAll(data)
    })
  }

  // 删除
  const onDelete = (id) => {
    api.delTransferAccountDel({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }
  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }
 
  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[value].content
    setFieldsValue({
      name: data.name ? data.name : undefined,
      idType: JSON.stringify(data.idType) ? data.idType : undefined,
      id: data.id ? data.id : undefined,
      verityStatus: JSON.stringify(data.verityStatus) ? data.verityStatus : undefined,
      contactName: data.contactName ? data.contactName : undefined,
      contactMobile: data.contactMobile ? data.contactMobile : undefined,
      contactMail: data.contactMail ? data.contactMail : undefined,
      run: data.run ? data.run : undefined,
      sale: data.sale ? data.sale : undefined,
      addTime: data.addTime ? [moment(data.addTime[0], 'YYYY-MM-DD'), moment(data.addTime[1], 'YYYY-MM-DD')] : undefined
    })
  }


  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        // console.log('Object.values(vals)', values)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }
  const { TabPane } = Tabs;

  function callback(key) {
    setLoading(true)
    filter.status = key
    api.getTransferList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    }
  }
  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="业务编号" labelCol={{ span: 9 }}>
                  {getFieldDecorator('no')(<Input placeholder="请输入业务编号" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转出人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('outName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转入人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('inName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="申请时间" labelCol={{ span: 9 }}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem label="审批通过时间" labelCol={{ span: 10 }}>
                  {getFieldDecorator('passTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
            <Col span={8}>
              <FormItem label="审批状态" labelCol={{ span: 9 }}>
                  {getFieldDecorator('approvalStatus')(
                  <Select placeholder="全部" showSearch={true} optionFilterProp="children">
                    {auditStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
        <div className={`search-btns ${expand ? '' : 'hide'}`}>
          <h4 className="filter-title">已保存筛选方案：</h4>
          <div className="filter-select">
            <Select
              style={{ display: 'inline-block', width: '160px' }}
              placeholder="请选择"
              value={statusVal}
              onDropdownVisibleChange={(e) => onChangeGetFilter(e)}
              onSelect={onChecked}
              dropdownMatchSelectWidth={false}
            >
              {
                filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )
              }
            </Select>
          </div>
          <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
        </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <Tabs defaultActiveKey="3" onChange={callback}>
        <TabPane tab="全部" key="3"></TabPane>
        <TabPane tab="审批中" key="1"></TabPane>
        <TabPane tab="已完成" key="2"></TabPane>
      </Tabs>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1400 }}
        onChange={onChangeTable}
      >
        <Column title="转出业务编号"
          dataIndex="no"
          fixed="left"
          width={180}
          ellipsis={true}
          render={(no, record) => <Link to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}><div title={no}>{no}</div></Link>}
        />
        <Column title="转出人" dataIndex="outName" fixed="left" width={80} />
        <Column title="所属部门" dataIndex="outDepartment" />
        <Column title="直属上级" dataIndex="outLeader" />
        <Column title="转入人" dataIndex="inName" />
        <Column title="所属部门" dataIndex="inDepartment" />
        <Column title="直属上级" dataIndex="inLeader"/>
        <Column title="审批状态" dataIndex="approvalStatus" />
        <Column title="申请时间" dataIndex="addTime" width={110} />
        <Column title="审批通过时间" dataIndex="approvalPassTime" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {
              record.editFlag ?
                <Tooltip title="编辑" placement="bottom">
                  <Link to={`/csr/turnAudit/apply?apid=${record.id}`}>
                    <Icon className="operate-icon" style={{ color: '#1890FF' }} type="edit" />
                  </Link>
                </Tooltip>
                : null
            }
            {' '}
            {
              record.delFlag ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }
          </>
        )} />
      </Table>
    </>
  )
}

export default Form.create()(AddCustomerAudit)
