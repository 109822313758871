/**
 * 模块名称: 信用账户统计列表
 * @author james
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Form,
  Button,
  Input,
  Row,
  Col,
  Alert
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
// import CorpFilter from '@/components/CorpFilter'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
let pageSize = 10
let currentPage = 1

let filter = {
  // companyId: '',
  oaqId: '',
  name: ''
}
let params = {}


const VendorIdAudit = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  // const [expand, setExpand] = useState(false)
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      // companyId: '',
      oaqId: '',
      name: ''
    }
    getList()
  }, [])

  const getList = () => {
    setLoading(true)
    api.getCreditAccountStaList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      // companyId: filter.companyId,
      oaqId: '',
      name: ''
    }
    history.replace(match.path)
    currentPage = 1
    getList(currentPage)
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  // const onChangeCorp = (data) => {
  //   filter.companyId = data.map(item => item.id).join(',')
  //   history.replace(match.path)
  //   currentPage = 1
  //   getList()
  // }

  // const onToggle = () => {
  //   setExpand(!expand)
  // }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      {/* <CorpFilter onChange={onChangeCorp} /> */}
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={10}>
                <FormItem label="QID/OAID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('oaqId')(<Input placeholder="请输入OAID" />)}
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="代理商/客户名称" labelCol={{ span: 8 }}>
                  {getFieldDecorator('name')(<Input placeholder="请输入客户名称" />)}
                </FormItem>
              </Col>

            </Row>
          </div>
        </div>

        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap"></div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="oaqId"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="QID/OAID" dataIndex="oaqId" width={120}/>
        <Column title="代理商/客户名称"
          dataIndex="name"
          width={280}
          ellipsis={true}
          render={(name, record) => <Link to={`${match.path}/detiall?oaqId=${record.oaqId}&name=${record.name}&type=${record.type}&time=${+new Date()}&sign=${CryptoJS.MD5(record.oaqId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{name}</Link>}
        />
        <Column title="客户类型" dataIndex="type" render={(type, record) => record.type == 0 ? <div>直销客户</div> : <div>代理商客户</div>}/>
        <Column title="信用总额度" dataIndex="totalAmount" />
        <Column title="已用信用总额度" dataIndex="usedAmount" />
        <Column title="可用信用总额度" dataIndex="trueAmount" />
      </Table>
    </>
  )
}

export default Form.create()(VendorIdAudit)
