import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  message,
  Form,
  Checkbox
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item

const coltypeData = [{
  id: 1,
  name: "正常收款"
}, {
  id: 2,
  name: "信用订单"
}, {
  id: 7,
  name: "厂商赠送"
}, {
  id: 3,
  name: "厂商直汇"
}]

const Coltype = (props) => {
  const { setColtypeVisible } = props
  const { getFieldDecorator, validateFields } = props.form
  const [saveLoading, setSaveLoading] = useState(false)
  const [defaultValue, setDefaultValue] = useState([])

  useEffect(() => {
    api.getColtype().then(data => setDefaultValue(data))
  }, [])

  // 设置收款方式
  const coltypeSubmit = evt => {
    evt.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        setSaveLoading(true)
        api.setColtype(vals).then(() => {
          setSaveLoading(false)
          setColtypeVisible(false)
          message.success('保存成功')
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18, offset: 6 },
  }

  return (
    <Form onSubmit={coltypeSubmit} {...formItemLayout}>
      <FormItem>
        {getFieldDecorator('coltype', {
          rules: [{ required: true, message: '请选择收款方式' }],
          initialValue: defaultValue
        })(
          <Checkbox.Group style={{ width: '100%' }}>
            {coltypeData.map(item => (
              <div key={item.id} style={{ paddingBottom: '30px' }}>
                <Checkbox value={item.id}>{item.name}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>,
        )}
      </FormItem>
      <BtnGroup onCancel={() => setColtypeVisible(false)} loading={saveLoading} />
    </Form>
  )
}

export default Form.create()(Coltype)