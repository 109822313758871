/**
 * 模块名称: 分配销售、客服
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  DatePicker,
  Spin,
  Alert
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
// import CompanyBackup from '@/components/CompanyBackup'
import CorpFilter from '@/components/CorpFilter'
// import { Link } from 'react-router-dom'
import Distribution from './module/Distribution'
import CustomerService from './module/CustomerService'
import Import from '@/components/Import'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  relBranchId: '',
  vendor: '',
  account: '',
  project: '',
  product: '',
  client: '',
  clientId: '',
  relServicerName: '',
  relSalerName: '',
  createTimeMin: '',
  createTimeMax: '',
}

// let selectedIds = ''

const HeadSetup = (props) => {
  const ImportRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [projectAll, setProjectAll] = useState([]) // 项目
  const [productAll, setProductAll] = useState([]) // 产品
  const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)
  const [salesVisible, setSalesVisible] = useState(false)
  const [salesloading, setSalesLoading] = useState(false) // 分配销售
  const [serviceVisible, setServiceVisible] = useState(false)
  const [serviceloading, setServiceLoading] = useState(false) // 分配客服
  const [selectedCount, setSelectedCount] = useState(0)
  const [visibleFile, setVisibleFile] = useState(false)
  const [selectedIds, setSelectedIds] = useState('')

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      relBranchId: '',
      vendor: '',
      account: '',
      project: '',
      product: '',
      client: '',
      clientId: '',
      relServicerName: '',
      relSalerName: '',
      createTimeMin: '',
      createTimeMax: '',
    }
    getList()
    getDirectVendorFilterData()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getDirectVendorList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          createTimeMin: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          createTimeMax: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : '',
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      relBranchId: filter.relBranchId,
      vendor: '',
      account: '',
      project: '',
      product: '',
      client: '',
      clientId: '',
      relServicerName: '',
      relSalerName: '',
      createTimeMin: '',
      createTimeMax: '',
    }
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 筛选条件
  const getDirectVendorFilterData = () => {
    api.getDirectVendorFilterData().then(data => {
      setProjectAll(data.data.project)
      setProductAll(data.data.product)
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.relBranchId = data.map(item => item.id).join(',')
    // filter.relBranchId = data.reduce((prev, curr) => [...prev, curr.id], []).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onSales = () => {
    if (selectedIds == '') {
      message.warning("请先选择厂商ID")
      return
    }
    setSalesVisible(true)
  }

  const onService = () => {
    if (selectedIds == '') {
      message.warning("请先选择厂商ID")
      return
    }
    setServiceVisible(true)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRows.length)
      // selectedIds = selectedRowKeys
      setSelectedIds(selectedRowKeys)
    },
    selectedRowKeys: selectedIds
  }

  //导入
  const onImportFile = () => {
    setVisibleFile(true)
    // onRemoved就是子组件暴露给父组件的方法
    ImportRef.current.onRemoved()
  }

  //点击确定导入
  const onSearchFile = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.setDirectVendorImport({
          file: fileList[0].url,
          companyId: filter.relBranchId
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportRef.current.onLoading()
          setVisibleFile(false)
        }).catch(err => {
          // console.log(err)
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  //点击导入取消按钮
  const onCloseImport = () => {
    setVisibleFile(false)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9},
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    }
  }

  return (
    <>
      {/* <CompanyBackup onChange={onChangeCorp} radio notHeadOffice /> */}
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="其他选项: 厂商ID" labelCol={{ span: 9 }}>
                  {getFieldDecorator('vendor')(<Input placeholder="请输入厂商ID" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="厂商账号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('account')(<Input placeholder="请输入厂商账号" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="项目" labelCol={{ span: 8 }}>
                  {getFieldDecorator('project')(
                    <Select placeholder="全部">
                      {projectAll && projectAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="产品" labelCol={{ span: 9 }}>
                  {getFieldDecorator('product')(
                    <Select placeholder="全部">
                      {productAll && productAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="OA客户名称" labelCol={{ span: 8 }}>
                  {getFieldDecorator('client')(<Input placeholder="请输入OA客户名称" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="OAID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('clientId')(<Input placeholder="请输入OAID" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="关联客服" labelCol={{ span: 8 }}>
                  {getFieldDecorator('relServicerName')(<Input placeholder="请输入关联客服" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="关联销售" labelCol={{ span: 8 }}>
                  {getFieldDecorator('relSalerName')(<Input placeholder="请输入关联销售" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="创建时间" labelCol={{ span: 9 }}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12} style={{ width: '100%' }} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Button type="primary" icon="download" onClick={onImportFile}>批量导入</Button>
        <Auth auths={authList} code="distribution-sales">
          <Button type="primary" className="add-SalesBtn" onClick={onSales}>分配销售</Button>
        </Auth>
        <Auth auths={authList} code="distribution-customer-service">
          <Button type="primary" className="add-SalesBtn" onClick={onService}>分配客服</Button>
        </Auth>
      </div>
      {/* <Alert className="corp-count" message={
          <>
            <span>已选择 <span style={{ color: '#168FFF' }}>{selectedCount}</span> 项</span>
            <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
          </>
        } type="info" showIcon /> */}
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />

      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="厂商ID" dataIndex="vendor" />
        <Column title="厂商账号" dataIndex="account" />
        <Column title="项目" dataIndex="projectName" />
        <Column title="产品" dataIndex="productName" />
        <Column title="OA客户名称"
          dataIndex="client"
          width={240}
          ellipsis={true}
        // render={(name, record) => <Link to={`${match.path}/detail?id=${record.id}`}>{name}</Link>}
        />
        <Column title="OAID" dataIndex="clientId" />
        <Column title="创建时间" dataIndex="createTime" width={120} />
        <Column title="关联客服" dataIndex="relServicerName" />
        <Column title="关联销售" dataIndex="relSalerName" />
      </Table>
      <Drawer
        title="分配销售"
        width={880}
        onClose={() => { setSalesVisible(false) }}
        visible={salesVisible}
        destroyOnClose={true}
      >
        <Spin spinning={salesloading}>
          <Distribution
            setSalesVisible={setSalesVisible}
            setSalesLoading={setSalesLoading}
            companyId={filter.relBranchId}
            setSelectedIds={setSelectedIds}
            vendorId={selectedIds}
            getList={getList}
          />
        </Spin>
      </Drawer>
      <Drawer
        title="分配客服"
        width={880}
        onClose={() => { setServiceVisible(false) }}
        visible={serviceVisible}
        destroyOnClose={true}
      >
        <Spin spinning={serviceloading}>
          <CustomerService
            setServiceVisible={setServiceVisible}
            setServiceLoading={setServiceLoading}
            companyId={filter.relBranchId}
            setSelectedIds={setSelectedIds}
            vendorId={selectedIds}
            getList={getList}
          />
        </Spin>
      </Drawer>
      <Import
        onSearch={onSearchFile}
        visibleFile={visibleFile}
        onCloseImport={onCloseImport}
        ImportTemplate="/api/File/DownTemplate?url=/templates/importvendor.xlsx&name=分配负责人导入模板"
        ImportRef={ImportRef}
      />
    </>
  )
}

export default Form.create()(HeadSetup)
