/**
 * 模块名称: 新直销厂商id转移申请
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  message,
  Descriptions,
  Upload,
  Spin,
  Row,
  Col,
  Radio,
  Select,
  Tooltip,
  Checkbox
} from 'antd'
import { useSelector } from 'react-redux'
import { parseSearch, debounceSeek } from '@/utils'
import { upLoadModule } from '@/utils/common'
import VendorsList from './module/VendorsList'

const { Column } = Table
const FormItem = Form.Item
const { Dragger } = Upload
const { Option } = Select

const NewVendor = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
  const { history, location } = props
  const dataVal = props.location.state ? props.location.state : null
  const [formLoading, setformLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)
  const userInfo = useSelector(state => state.userInfo)
  const [selectData, setSelectData] = useState({
    relSalerData: [], // 销售
    relServicerData: [], // 客服
    relAgentStaffData: [], // 代理商
    branchIdData: [], // 默认主体
  })
  const [clientList, setClientList] = useState([]) // 客户
  const [clientLoading, setclientLoading] = useState(false)
  const [clientIdVal, setClientIdVal] = useState('')
  const [agentList, setAgentList] = useState([]) // 代理商
  const [agentLoading, setAgentLoading] = useState(false)
  const [agentIdVal, setAgentIdVal] = useState('')
  const [fundAccountList, setFundAccountList] = useState([])
  const [accountId, setAccountId] = useState('')
  const [vendorData, setVendorData] = useState([])
  const [outInfoData, setOutInfoData] = useState(null) // 返显转出数据
  const search = parseSearch(location.search)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])


  useEffect(() => {
    // 驳回重提返显(编辑)
    if (search.editId) {
      api.getTransferDirectRepeatData({ id: search.editId }).then(data => {
        const { outInfo, muilt, intoType, remark } = data
        const { relSaler, relServicer, relAgentStaff, client, clientId, branchId, agentId, agent } = data.intoInfo
        setOutInfoData(outInfo)
        if (muilt === 0) {
          setVendorData(outInfo.list)
        } else {
          setSelectedRowKeys(outInfo.list.map(v => v.id))
        }
        getSelectData().then(() => {
          setFieldsValue({
            intoType,
            remark,
            relSaler,
            relServicer,
            relAgentStaff,
            agreement: true
          })
        })
        if (Array.isArray(data.files) && data.files.length) {
          data.files.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(data.files.map((item, index) => ({ ...item, uid: index })))
        }
        if (+intoType === 5) {
          api.getTransferClientList({ name: client, limit: 10 }).then(res => {
            setClientList(res)
            setTimeout(() => {
              setFieldsValue({
                clientId,
              })
            }, 300)
            setClientIdVal(clientId)
          })
        }

        if (+intoType === 6) {
          api.getTransferDirectAgentList({ name: agent, limit: 10 }).then(res => {
            setAgentList(res)
            setTimeout(() => {
              setFieldsValue({
                agentId,
              })
            }, 300)
            setAgentIdVal(agent)
          })
          api.getTransferClientList({ name: client, qid: agentId, limit: 10 }).then(res => {
            setClientList(res)
            setTimeout(() => {
              setFieldsValue({
                clientId,
              })
            }, 300)
            setClientIdVal(clientId)
          })
        }
        // 主体
        api.getTransferDirectBranchList({ userId: relSaler ? relSaler : relServicer ? relServicer : relAgentStaff }).then(res => {
          setFundAccountList(res)
          setFieldsValue({
            branchId,
          })
        })
      })
    } else { // 新增
      getSelectData()
      getTransferDirectBranch()
      if (dataVal.muilt === 0) {
        getTransferVendors(dataVal.muilt, dataVal.vendorId)
      }
    }
  }, [])

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getTransferStaffList({ type: 1 }),
      api.getTransferStaffList({ type: 2 }),
      api.getTransferStaffList({ type: 3 }),
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        relSalerData: list[0],
        relServicerData: list[1],
        relAgentStaffData: list[2],
      })
      return list
    }).catch(() => setformLoading(false))
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.rar, .png, .jpg, .jpeg, .PDF, .zip',
    allowSizeType: 2
  })

  const ongoback = () => {
    history.goBack()
  }

  const getTransferVendors = (muilt, id) => {
    api.getTransferVendors({ muilt, vendorId: id, limit: global.paramsLimit }).then(res => {
      setVendorData(res.list)
    })
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    const { muilt } = dataVal

    if (muilt !== 0 && selectedRowKeys.length <= 0) {
      message.warning("请选择转出厂商信息")
      return
    }

    validateFields((err, values) => {
      // 去掉参数中的无效值
      Object.keys(values).forEach(key => {
        if ([undefined, null, ''].includes(values[key])) {
          delete values[key]
        }
      })
      let params = Object.assign({})
      let outInfo = {} // 转出对象
      if (muilt === 0) {
        outInfo.vendors = vendorData && vendorData[0].id
      } else {
        outInfo.vendors = selectedRowKeys.join(',')
      }

      let { agreement, remark, intoType, ...intoInfo } = values // 转出内容

      params.muilt = muilt
      params.outInfo = outInfo
      params.intoInfo = intoInfo
      params.intoType = values.intoType
      params.remark = values.remark

      // 直销类型
      if (values.relServicer && values.relSaler) {
        message.warning("销售和客服不能同时选，最多选择其中一个")
        return
      }
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      params.files = uploadList
      if (search.editId) params.id = search.editId
      if (!search.editId) params.id = ''
      if (!err) {
        api.getTransferDirectVendorsubmit2(params).then(res => {
          // console.log("res", res)
          message.success("提交成功")
          history.push('/csr/vendorIdAudit')
        }).catch(() => {
        })
      }
    })
  }

  const onChangeType = (e) => {
    setFieldsValue({
      agentId: undefined,
      clientId: undefined,
      relSaler: undefined,
      relServicer: undefined,
      relAgentStaff: undefined,
      agreement: undefined
    })
    setClientList([])
    setClientIdVal('')
    setAgentIdVal('')
    getTransferDirectBranch()
  }

  // 直销 客户模糊搜索
  const onSearchClient = (value) => {
    if (value.trim() !== '') {
      setclientLoading(true)
      api.getTransferClientList({ name: value, limit: 51 }).then(res => {
        if (res.length >= 50) {
          res.push({ id: -1, name: '……' })
          setClientList(res)
        } else {
          setClientList(res)
        }
        setclientLoading(false)
      })
    }
  }

  // 代理商 里客户
  const getTransferList = (value, qid) => {
    api.getTransferClientList({ name: value, qid, limit: 51 }).then(res => {
      if (res.length >= 50) {
        res.push({ id: -1, name: '……' })
        setClientList(res)
      } else {
        setClientList(res)
      }
      setclientLoading(false)
    })
  }

  const onSelectClient = (val) => {
    setClientIdVal(val)
  }

  // 选择代理商名称
  const onSelectAgent = (val) => {
    setAgentIdVal(val)
    setFieldsValue({
      clientId: undefined
    })
    setClientIdVal('')
    getTransferList('', val)
  }

  // 代理商 里的客户
  const onSearchClientAgent = (value) => {
    if (value.trim() !== '') {
      setclientLoading(true)
      getTransferList(value, getFieldValue('agentId'))
    }
  }

  // 代理商模糊搜索
  const onSearchAgent = (value) => {
    if (value.trim() !== '') {
      setAgentLoading(true)
      api.getTransferDirectAgentList({ name: value, limit: 51 }).then(res => {
        if (res.length >= 50) {
          res.push({ id: -1, name: '……' })
          setAgentList(res)
        } else {
          setAgentList(res)
        }
        setAgentLoading(false)
      })
    }
  }

  // 获取主体
  const getTransferDirectBranch = (userId) => {
    setAccountId('')
    setFieldsValue({
      branchId: undefined,
    })
    api.getTransferDirectBranchList({ userId }).then(res => {
      setFundAccountList(res)
    })
  }

  // 选择销售
  const onSelectRelS = (val) => {
    if (val) {
      setFieldsValue({
        relServicer: undefined,
        relAgentStaff: undefined
      })
      getTransferDirectBranch(val)
    }
  }

  // 选择客服
  const onSelectRelC = (val) => {
    if (val) {
      setFieldsValue({
        relSaler: undefined,
        relAgentStaff: undefined
      })
      getTransferDirectBranch(val)
    }
  }
  // 选择代理商开发
  const onSelectRelA = (val) => {
    if (val) {
      setFieldsValue({
        relSaler: undefined,
        relServicer: undefined
      })
      getTransferDirectBranch(val)
    }
  }

  const selectZhuTi = (id) => {
    let name = ''
    for (let i = 0; i < fundAccountList.length; i++) {
      if (fundAccountList[i].id == id) {
        name = fundAccountList[i].name
      }
    }

    setAccountId(id)
    // setAccountName(name)
    setFundAccountList(fundAccountList.map(item => {
      let showYE = false;
      if (item.id === id) {
        showYE = true;
      }
      return { ...item, showYE }
    }))
  }

  const MyRadio = (props) => {
    const [showYE, setShowYE] = useState(false);
    const { data } = props
    useEffect(() => {
      setShowYE(data.showYE !== undefined ? data.showYE : false)
    }, [data])
    return <>
      <Radio.Button className="radio-container" value={data.id}>
        <img className="mainNameBodySelLogo" src={data.logo} alt="" />{data.name}
        <Icon className="check" type="check" />
      </Radio.Button>{showYE ? <span>&nbsp;</span> : <span>&nbsp;</span>}
    </>
  }

  const onCheckboxChange = (e) => {
    const { checked } = e.target
    setTimeout(() => {
      setFieldsValue({
        agreement: checked ? checked : undefined
      })
    }, 500)
  }

  return (
    <Spin spinning={formLoading}>
      {/* 公共转出信息 */}
      <div className="pro-detail">
        {dataVal && dataVal.muilt === 0 ? <h3>厂商ID转移申请</h3> : null}
        {dataVal && dataVal.muilt === 1 ? <h3>批量厂商ID转移申请</h3> : null}
        {dataVal && dataVal.muilt === 2 ? <h3>批量客户厂商ID转移申请</h3> : null}
        <div className="line-box"></div>
        <h3 style={{ marginTop: 12 }}>转出信息</h3>
        <div className="customerLine"></div>
        <Descriptions
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
          column={4}
        >
          <dItem label="申请人">
            {!search.editId ? userInfo.staffName : outInfoData && outInfoData.userName}
          </dItem>
          <dItem label="直属上级">
            {!search.editId ? userInfo.leaderName : outInfoData && outInfoData.userLeaderName}
          </dItem>
          <dItem label="所属部门">
            {!search.editId ? userInfo.departmentName : outInfoData && outInfoData.userDeptName}
          </dItem>
        </Descriptions>
        <div style={{height: '12px'}}></div>
      </div>

      {/* 单个转出厂商信息 */}
      <div className="pro-detail">
        <div className="line-box"></div>
        <h3 style={{ marginTop: 12 }}>转出厂商信息</h3>
        <div className="customerLine"></div>
        {dataVal && dataVal.muilt === 0 &&
          <Table
            dataSource={vendorData}
            rowKey="id"
            className="table-BatchAdd"
            scroll={{ x: 'max-content' }}
          >
            <Column title="项目" dataIndex="projectName" />
            <Column title="产品" dataIndex="productName" />
            <Column title="厂商ID" dataIndex="vendor" />
            <Column title="厂商属性" dataIndex="typeName" />
            <Column title="OA客户名称" dataIndex="client" />
            <Column title="OAID" dataIndex="clientId" />
            <Column title="关联主体" dataIndex="relBranch" />
            <Column title="关联销售" dataIndex="relSalerName" />
            <Column title="关联客服" dataIndex="relServicerName" />
            <Column title="关联代理商开发" dataIndex="relAgentStaffName" />
            <Column title="创建时间" dataIndex="createTime" />
          </Table>
        }
        {
          dataVal && dataVal.muilt !== 0 &&

          <VendorsList
            muilt={dataVal.muilt}
            client={dataVal.client}
            clientId={dataVal.clientId}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
          />
        }
        <div className="line-box"></div>
      </div>

      <Form onSubmit={formSubmit}>
        {/* 转入厂商信息 */}
        <div className="pro-detail">
          <div className="line-box"></div>
          <h3 style={{ marginTop: 12 }}>转入信息</h3>
          <div className="customerLine"></div>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="客户类型">
                {getFieldDecorator('intoType', {
                  initialValue: 4,
                  rules: [{ required: false, message: '请输入客户类型' }]
                })(
                  <Radio.Group onChange={onChangeType} >
                    <Radio value={4}>不变</Radio>
                    <Radio value={5}>直销</Radio>
                    <Radio value={6}>代理商</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            {
              getFieldValue('intoType') === 5 &&
              <>
                <Col span={6}>
                  <Form.Item label="转入客户名称">
                    {getFieldDecorator('clientId', {
                      rules: [{ required: true, message: '请输入转入客户名称' }]
                    })(
                      <Select
                        placeholder="请输入转入客户名称"
                        style={{ width: 240 }}
                        showSearch={true}
                        onSearch={debounceSeek(onSearchClient, 300)}
                        onSelect={onSelectClient}
                        onBlur={onSelectClient}
                        filterOption={false}
                        loading={clientLoading}
                        getPopupContainer={trigger => trigger.parentNode}
                        // notFoundContent={clientLoading ? <Spin size="small" /> : null}
                        dropdownMatchSelectWidth={false}
                      >
                        {clientList && clientList.map(item => {
                          if(item.id === -1) {
                            return <Option style={{ fontSize: '22px', marginTop: '-14px',color: '#000000A6'}} key={item.id} disabled={true} value={item.id}>{item.name}</Option>
                          } else {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                          }
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="转入OAID" >
                    <div>{clientIdVal}</div>
                  </Form.Item>
                </Col>
              </>
            }
          </Row>
          {
            getFieldValue('intoType') === 6 &&
            <>
              <Row>
                <Col span={6}>
                  <Form.Item label="转入代理商名称">
                    {getFieldDecorator('agentId', {
                      rules: [{ required: true, message: '请输入转入代理商名称' }]
                    })(
                      <Select
                        placeholder="请输入转入代理商名称"
                        style={{ width: 240 }}
                        showSearch={true}
                        onSearch={debounceSeek(onSearchAgent, 300)}
                        onSelect={onSelectAgent}
                        onBlur={onSelectAgent}
                        filterOption={false}
                        loading={agentLoading}
                        getPopupContainer={trigger => trigger.parentNode}
                        // notFoundContent={agentLoading ? <Spin size="small" /> : null}
                        dropdownMatchSelectWidth={false}
                      >
                        {agentList && agentList.map(item => {
                          if(item.id === -1) {
                            return <Option style={{ fontSize: '22px', marginTop: '-14px',color: '#000000A6'}} key={item.id} disabled={true} value={item.id}>{item.name}</Option>
                          } else {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                          }
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="转入代理商ID" >
                    <div>{agentIdVal}</div>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="转入客户名称">
                    {getFieldDecorator('clientId', {
                      rules: [{ required: true, message: '请输入转入客户名称' }]
                    })(
                      <Select
                        placeholder="请输入转入客户名称"
                        style={{ width: 240 }}
                        showSearch={true}
                        onSearch={debounceSeek(onSearchClientAgent, 300)}
                        onSelect={onSelectClient}
                        onBlur={onSelectClient}
                        filterOption={false}
                        loading={clientLoading}
                        getPopupContainer={trigger => trigger.parentNode}
                        // notFoundContent={clientLoading ? <Spin size="small" /> : null}
                        dropdownMatchSelectWidth={false}
                      >
                        {clientList && clientList.map(item => {
                          if(item.id === -1) {
                            return <Option style={{ fontSize: '22px', marginTop: '-14px',color: '#000000A6'}} key={item.id} disabled={true} value={item.id}>{item.name}</Option>
                          } else {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                          }
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="转入OAID" >
                    <div>{clientIdVal}</div>
                  </Form.Item>
                </Col>
              </Row>
            </>
          }
          {
            <Row>
              <Col span={6}>
                <Form.Item label="所属销售">
                  {getFieldDecorator('relSaler', {
                    rules: [{ required: false, message: '请输入所属销售' }]
                  })(
                    <Select
                      placeholder="请输入所属销售"
                      style={{ width: 240 }}
                      showSearch={true}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                      onSelect={onSelectRelS}
                      onBlur={onSelectRelS}
                      disabled={getFieldValue('intoType') === 6 ? true : false}
                    >
                      {selectData.relSalerData && selectData.relSalerData.map(item => <Option key={item.id}
                        value={item.id}>{item.staffName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="所属客服">
                  {getFieldDecorator('relServicer', {
                    rules: [{ required: false, message: '请输入所属客服' }]
                  })(
                    <Select
                      placeholder="请输入所属客服"
                      style={{ width: 240 }}
                      showSearch={true}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                      onSelect={onSelectRelC}
                      onBlur={onSelectRelC}
                      disabled={getFieldValue('intoType') === 6 ? true : false}
                    >
                      {selectData.relServicerData.map(item => <Option key={item.id}
                        value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属代理商开发">
                  {getFieldDecorator('relAgentStaff', {
                    rules: [{ required: false, message: '请输入代理商开发' }]
                  })(
                    <Select
                      placeholder="请输入所属代理商开发"
                      style={{ width: 240 }}
                      showSearch={true}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                      onSelect={onSelectRelA}
                      onBlur={onSelectRelA}
                      disabled={getFieldValue('intoType') === 5 ? true : false}
                    >
                      {selectData.relAgentStaffData.map(item => <Option key={item.id}
                        value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                  <Tooltip
                    title="直销客户的厂商ID关联的销售和客服，不能与代理商的客户的厂商ID关联的代理商所属开发同时存在。"
                    placement="bottomLeft"
                  >
                    <Icon type="question-circle" style={{ marginLeft: '10px', fontSize: '18px', color: '#eda355' }} />
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          }
          <Form.Item>
            {getFieldDecorator('agreement', {
              valuePropName: 'checked',
              rules: [{ required: true, message: '确认信息为必选项' }]
            })(
              <Checkbox onChange={onCheckboxChange}>
                <span style={{ color: 'red' }}>请确认厂商ID转出后，该厂商ID在原客户下的消耗不能退单。</span>
              </Checkbox>,
            )}
          </Form.Item>
          <div className="line-box"></div>
        </div>
        <div className="customerSty">
          <h3 style={{ marginTop: 12 }}>转入主体</h3>
          <div className="customerLine"></div>
          <div className="pro-detail-smailtitle mainNameBodyRadio">
            <Form.Item label="" name="layout">
              {getFieldDecorator('branchId', {
                // initialValue: search.id > 0 ? manufacturerData.account : undefined,
                rules: [{ required: true, message: '请选择主体' }]
              })(
                <Radio.Group onChange={(e) => selectZhuTi(e.target.value)}>
                  {fundAccountList.map(item => <MyRadio key={item.id} data={item} />)}
                </Radio.Group>
              )}
            </Form.Item>
          </div>
        </div>

        {/* 其他信息 */}
        <div className="customerSty">
          <h3 style={{ marginTop: 12 }}>其他信息</h3>
          <div className="customerLine"></div>
          <div>
            <div style={{width: '60%'}}>
              <FormItem label="附件">
                <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                    {...uploadFiles}
                    style={{width: '90%'}}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      附件支持格式：rar、zip、PDF、JPG、PNG 支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
                  </p>
                  </Dragger>
                </div>
              </FormItem>
            </div>
            <FormItem label="备注">
              {getFieldDecorator('remark')(
                <Input.TextArea placeholder="请输入备注" maxLength={100}/>
              )}
            </FormItem>
            <div className="bizOrder-btn">
              <Button onClick={ongoback} style={{ marginRight: 20 }}>取消</Button>
              <Button style={{ marginRight: 260 }} type="primary" htmlType="submit">提交</Button>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  )
}
export default Form.create()(NewVendor)
