/**
 * 模块名称: 事业群管理
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  InputNumber,
  Tooltip
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let filter = {
  name: '',
  // companyId: '',
}
let tmpFieldValue = {}

const Department = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [leaderList, setLeaderList] = useState([])
  const [directValue, setDirectValue] = useState(0)
  const [channelValue, setChannelValue] = useState(0)
  const [useDirectValue, setUseDirectValue] = useState(0)
  const [useChannelValue, setUseChannelValue] = useState(0)
  const [authList, setAuthList] = useState([])
  const [drawerTitle, setDrawerTitle] = useState('添加事业群')
  const search = parseSearch(location.search)

  currentPage = search.page ? +search.page : 1
  pageSize = search.pageSize ? +search.pageSize : 10

  useEffect(() => {
    filter = {
      name: '',
      // companyId: ''
    }
    getGroup()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getGroup = () => {
    setLoading(true)
    api.getGroup({
      // companyId: filter.companyId,
      name: filter.name,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (JSON.stringify(vals) == JSON.stringify(tmpFieldValue)){//eslint-disable-line
        setSaveLoading(false)
        setSaveLoading(false)
        setVisible(false)
        return
      }
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        //console.log('submit vals', vals)
        setSaveLoading(true)
        api.saveGroup(vals).then(() => {
          setSaveLoading(false)
          setVisible(false)
          message.success('添加成功')
          getGroup()
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const onDelete = (id) => {
    api.delGourp({ id }, true).then(() => {
      message.success('删除成功')
      getGroup()
    })
  }

  const onEdit = (data) => {
    setVisible(true)
    setDrawerTitle('编辑事业群')
    editId = data.id

    // 已用额度
    setUseDirectValue(data.directUsedAmount)
    setUseChannelValue(data.channelUsedAmount)

    // select选择框
    setDirectValue(data.directLimitAmount ? 1 : 0)
    setChannelValue(data.channelLimitAmount ? 1 : 0)
    getLeaderList(data).then(() => setFormValue(data))
  }

  const setFormValue = data => {
    const direct = data.directLimitAmount
    const channel = data.channelLimitAmount
    setFieldsValue({
      name: data.name,
      directLimitAmount: direct ? direct: '',
      channelLimitAmount: channel ? channel :'',
      leaderId: data.leaderId
    })

    tmpFieldValue = {
      name: getFieldValue('name'),
      leaderId: getFieldValue('leaderId'),
      directLimitAmount: getFieldValue('directLimitAmount'),
      channelLimitAmount: getFieldValue('channelLimitAmount')
    }
  }

  const getLeaderList = (editData) => {
    setformLoading(true)
    return api.getLeaderList({ limit: global.paramsLimit, minJobPosition: 4 }).then(data => {
      const list = data.list
      // 修改
      if (editData) {
        let findIndex = -1
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === editData.leaderId) {
            findIndex = i
            break
          }
        }
        if (findIndex === -1) {
          list.push({ id: editData.leaderId, staffName: editData.leaderName })
        }
      }
      // console.log('list')
      setLeaderList(list)
      setformLoading(false)
      return list
    }).catch(() => setformLoading(false))
  }

  // 添加
  const onAddCorp = () => {
    editId = ''
    setDrawerTitle('添加事业群')
    setVisible(true)
    resetFields()
    setDirectValue(0)
    setChannelValue(0)
    getLeaderList()
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getGroup()
  }

  const onSort = (id, flow) => {
    api.updateGroupSort({ id, flow }).then(getGroup)
  }

  const departInp = useRef(null)

  const onSearch = () => {
    filter.name = departInp.current.value
    history.replace(match.path)
    currentPage = 1
    getGroup()
  }

  const onReset = () => {
    filter.name = ''
    currentPage = 1
    history.replace(match.path)
    departInp.current.value = ''
    getGroup()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const onChangeSlect = (type, val) => {
    if (type === 'direct') {
      setDirectValue(val)
      if (val === 0) {
        setFieldsValue({
          directLimitAmount: ''
        })
      }
    }
    if (type === 'channel') {
      setChannelValue(val)
      if (val === 0) {
        setFieldsValue({
          channelLimitAmount: ''
        })
      }
    }
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <div className="filter-wrap" style={{paddingBottom: 10}}>
        <div className="filter-item">
          <input className="ant-input" ref={departInp} type="text" placeholder="事业群名称" onKeyUp={onKeyup}/>
        </div>
        <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="add">
          <Button type="primary" icon="plus" onClick={onAddCorp}>添加</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1200 }}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" fixed="left" width={100} />
        <Column title="事业群名称" dataIndex="name" fixed="left" width={150} />
        <Column title="直销负利额度" key="directLimitAmount" render={text => text.directLimitAmount || '不限'} />
        <Column title="直销已用额度" key="directUsedAmount" render={text => text.directUsedAmount || '不限'} />
        <Column title="渠道负利额度" key="channelLimitAmount" render={text => text.channelLimitAmount || '不限'} />
        <Column title="渠道已用额度" key="channelUsedAmount" render={text => text.channelUsedAmount || '不限'} />
        <Column title="排序" key="sort" render={text => (
          <span className="caret-wrap">
            <Icon type="caret-up" onClick={() => onSort(text.id, 'up')} />
            <Icon type="caret-down" onClick={() => onSort(text.id, 'down')} />
          </span>
        )} />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Auth auths={text.operateAuth} code="edit">
              <Tooltip title="编辑" placement="bottom">
                <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
              </Tooltip>
            </Auth>
            {' '}
            <Auth auths={text.operateAuth} code="delete">
              <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Tooltip title="删除" placement="bottom">
                  <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                </Tooltip>
              </Popconfirm>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title={drawerTitle}
        width={600}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="事业群名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入事业群名称' }]
              })(<Input placeholder="请输入事业群名称" />)}
            </FormItem>
            <FormItem label="事业群负责人">
              {getFieldDecorator('leaderId')(
                <Select placeholder="请选择事业群负责人" showSearch={true} optionFilterProp="children">
                  {leaderList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="直销负利额度" className="select-input">
              <Select value={directValue} onChange={val => onChangeSlect('direct', val)}>
                <Option value={0}>不限</Option>
                <Option value={1}>限制</Option>
              </Select>
              {getFieldDecorator('directLimitAmount')(<InputNumber disabled={directValue === 0} />)}
              <div className="amount-tag">直销已用额度：{useDirectValue || 0}元</div>
            </FormItem>
            <FormItem label="渠道负利额度" className="select-input">
              <Select value={channelValue} onChange={val => onChangeSlect('channel', val)}>
                <Option value={0}>不限</Option>
                <Option value={1}>限制</Option>
              </Select>
              {getFieldDecorator('channelLimitAmount')(<InputNumber disabled={channelValue === 0} />)}
              <div className="amount-tag">渠道已用额度：{useChannelValue || 0}元</div>
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(Department)