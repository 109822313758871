/**
 * 模块名称: 转户审批
 * @author James
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TransferList from './TransferList'
import TransferDetail from './TransferDetail'
import TransferApply from './TransferApply'
const ClientTransfer = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/list'} component={TransferList} />
        <Route path={match.path + '/detail'} component={TransferDetail} />
        <Route path={match.path + '/apply'} component={TransferApply} />
        <Route component={TransferList} />
      </Switch>
    </div>
  )
}

export default ClientTransfer