/**
 * 模块名称: 客户认证(新增)审批详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  Alert,
  message,
  Spin 
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
let pageSize = 10
let currentPage = 1

const AddCustomerDetail = (props) => {
  const { match, location, history } = props
  const [detailData, setDetailData] = useState({})
  const [detailDataId, setdetailDataId] = useState('')
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState([])
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [showFile, setShowFile] = useState([]) //附件
  const [sameNameList, setSameNameList] = useState([])
  const [count, setCount] = useState(0)
  const search = parseSearch(location.search)
  const [hintVisible, setHintVisible] = useState(false) //提示弹框
  const [hintContent, setHintContent] = useState('')
  const [infoLoading, setInfoLoading] = useState(false)//loading
  const [btnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      setInfoLoading(true)
      api.getClientVerifyDetail({ id: search.id, type: 0 }).then(data => {
        setInfoLoading(false)
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.audit_flow) // 审批流
        setLogConList(data.audit_log) // 审批记录表
        setButtonAuthList(data.auditAuth) // 审批权限
        setShowFile(data.affix) //附件
      }).catch(() => setInfoLoading(false))
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      getSameNameList()
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  const getSameNameList = () => {
    api.getClientVerifySameNameList({ id: search.id, limit: pageSize, page: currentPage }).then(data => {
      setSameNameList(data.list)
      setCount(data.count)
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getSameNameList()
  }

  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
    setBtnLoading(false)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    setBtnLoading(true)
    const { verifyType, verifyNumber, name, id } = detailData
    api.checkVerifynNumber({
      verifyType,
      verifyNumber,
      name,
      id,
      type: 1
    }).then(data => {
      if (!Array.isArray(data)) {  //法务审批时有重复客户提示
        setHintContent(data)
        setHintVisible(true)
        setBtnLoading(false)
      } else {
        getPassConfirm()
      }
    }).catch(() => {
      setBtnLoading(false)
    })
  }

  // 通过或点通过后继续
  const getPassConfirm = () => {
    setBtnLoading(true)
    setInfoLoading(true)
    api.getClientVerifyAudit({
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }).then(data => {
      setBtnLoading(false)
      api.getClientVerifyDetail({ id: search.id, type: 0 }).then(data => {
        setInfoLoading(false)
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.audit_flow) // 审批流
        setLogConList(data.audit_log) // 审批记录表
        setButtonAuthList(data.auditAuth) // 审批权限
        setShowFile(data.affix) //附件
      }).catch(() => {
        setInfoLoading(false)
        setBtnLoading(false)
      }) //确认通过后
    }).catch(() => {
      setBtnLoading(false)
      setInfoLoading(false)
    })
    setModalVisible(false)
    setHintVisible(false)
  }

  const onHintCancel = () => {
    setHintVisible(false)
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setBtnLoading(false)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    setBtnLoading(true)
    setInfoLoading(true)
    api.getClientVerifyAudit({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(data => {
      setBtnLoading(false)
      api.getClientVerifyDetail({ id: search.id, type: 0 }).then(data => {
        setInfoLoading(false)
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.audit_flow) // 审批流
        setLogConList(data.audit_log) // 审批记录表
        setButtonAuthList(data.auditAuth) // 审批权限
        setShowFile(data.affix) //附件
      }).catch(() => {
        setBtnLoading(false)
        setInfoLoading(false)
      }) //确认驳回后
    }).catch(() => {
      setBtnLoading(false)
      setInfoLoading(false)
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 温馨提示
  const hintTitle = () => {
    return <div className='customerAdd-hintTitle'>
      <Icon type="warning" theme="twoTone" twoToneColor="#faad14" style={{ fontSize: '26px' }} />
      <div className='hintText'>温馨提示</div>
    </div>
  }

  // 转上级
  const onTurnUp = () => {
    api.getClientVerifyAudit({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(data => {
      history.push('/csr/addAudit')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.getClientVerifyAudit({
      id: detailDataId,
      type: 3,
      remark: ''
    }).then(data => {
      history.push('/csr/addAudit')
    }).catch()
  }

  return (
    <Spin spinning={infoLoading}>
      <div className="pro-detail">
        <Descriptions title="审批进度" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                    // `${item.persons.map((item)=>item)} ${item.statusName} ${item.time}`
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        <Descriptions title="客户信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="客户名称">
            {detailData.name}
          </dItem>
          {/* <dItem label="所在行业">
            {detailData.industryName}
          </dItem> */}
           <dItem label="一级行业">
            {detailData.industry1Name}
          </dItem>
          <dItem label="二级行业">
            {detailData.industry2Name}
          </dItem>
          <dItem label="地区">
            {detailData.area}
          </dItem>
          <dItem label="网址">
            {detailData.website}
          </dItem>
          <dItem label="认证号码类型">
            {detailData.verifyTypeName}
          </dItem>
          <dItem label="认证号码">
            {detailData.verifyNumber}
          </dItem>
          <dItem label="地址" span={3}>
            {detailData.address}
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions title="其他信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="附件" span={3}>
            <>
              {/* {
                showFile && showFile.map((item, i) => {
                  return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                })
              } */}
              {
                showFile && showFile.length >= 1 ? showFile.map((item, i) => {
                  return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : '无'
              }
            </>
          </dItem>
          <dItem label="备注">
            <>
              {detailData.remark ? detailData.remark : '无'}
            </>
          </dItem>
        </Descriptions>
        {/* 提示信息 */}
        <div className="line-box"></div>
        <Descriptions
          title="提示信息"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          dataSource={sameNameList}
          rowKey="id"
          className="table-page"
          // loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="OAID" dataIndex="id" />
          <Column title="客户名称" dataIndex="name" />
          <Column title="真实性验证号码类型" dataIndex="verifyTypeName" />
          <Column title="真实性验证号码" dataIndex="verifyNumber" />
        </Table>
        {/* 审批记录 */}
        <div className="line-box"></div>
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="审批状态" dataIndex="status" />
          <Column title="审批意见" dataIndex="chargeMsg" />
        </Table>
        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={debounce(() => {onReplenish()}, 3000)}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={debounce(() => {onTurnUp()}, 3000)}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            // title="确认驳回"
            title={turnNode()}
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
            footer={[
              <Button key="back" onClick={onTurnCancel}>取消</Button>,
              <Button key="submit" type="primary" loading={btnLoading} onClick={onTurnApprove}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
            footer={[
              <Button key="back" onClick={onPassCancel}>取消</Button>,
              <Button key="submit" type="primary" loading={btnLoading} onClick={onApprove}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
        <Modal
          title={hintTitle()}
          visible={hintVisible}
          onOk={getPassConfirm}
          maskClosable={false}
          closable={false}
          okText="继续"
          onCancel={onHintCancel}
          width='50%'
          footer={[
            <Button key="back" onClick={onHintCancel}>取消</Button>,
            <Button key="submit" type="primary" loading={btnLoading} onClick={getPassConfirm}>继续</Button>
          ]}
        >
          <div style={{ whiteSpace: 'pre-wrap' }}>
              {hintContent}
          </div>
        </Modal>
      </div>
    </Spin>
  )
}

export default AddCustomerDetail