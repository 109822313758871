/**
 * 所属公司过滤取
 * 接收onChange事件 以数组的格式返回所选公司的全部数据
 */

import React, { useState, useEffect } from 'react'
import {Tag, Switch, message} from 'antd'

const { CheckableTag } = Tag

const ProjectsList = props => {
  const { value, list,type , updateValidate,validated,onSelectedProjects} = props
  // const [checkedAll, setCheckedAll] = useState(false)
  const [theList, setTheList] = useState([])
  const [validateChecked, setValidateChecked] = useState(false)

  useEffect(() => {
    // setCheckedAll(value.length === list.length)
    const theVal = value.map(item => item.id)
    setTheList(list.map(item =>  ({...item, checked:theVal.includes(item.id)})))
  }, [value])

  useEffect(() => {
    setValidateChecked(validated?true:false)
  }, [validated])

  const onChange = (checked, id) => {
    if (type === 2 && !validateChecked) {
      message.info('失效时，不可操作')
      return
    }
    let list = theList.map(item => item.id === id ? { ...item, checked } : item)
    setTheList( list )
    const theVals = list.filter(item=>item.checked)
    // setCheckedAll(theVals.length === list.length)
    onSelectedProjects(theVals)
  }

  const onChangeAll = checked => {
    let list = theList.map(item => ({ ...item, checked }));
    setTheList(list)
    let theVals = list.filter(item => item.checked);
    // setCheckedAll(theVals.length === list.length)
    onSelectedProjects(theVals)
  }

  const onChangeSwitch = (checked) => {
    onChangeAll(false)
    updateValidate(checked)
    setValidateChecked(checked)
  }


  return (
    <div className="projectsListSty">
      {
        (() => {
          if(type === 1){
            return null;
          }
          return (
              <div className="corp-filter-wrap" style={{marginLeft:'5px',marginBottom:'10px'}}>
                <div style={{marginRight:'23px'}}>
                  生效
                </div>
                <Switch checkedChildren="是" onChange={onChangeSwitch} unCheckedChildren="否" checked={validateChecked} />
              </div>
          )
        })()
      }
      <div className="corp-filter-wrap corp-filter-wrap-set">
        <div className="corp-filter-wrap-set-item">
          {theList.length ? theList.map(d => <CheckableTag key={d.id} checked={d.checked} onChange={checked => onChange(checked, d.id)}>{d.name}</CheckableTag>) : '该公司下没有设置项目'}
        </div>
      </div>
    </div>
  )
}

export default ProjectsList