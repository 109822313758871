/**
 * 模块名称: 信用账户统计
 * @author james
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
// import CreditStatistics from './CreditStatistics'
import CreditStatisticsList from './CreditStatisticsList'
import CreditStatisticsDetiall from './CreditStatisticsDetiall'

const CreditStatistics = ({ match, history }) => {

  return (
    <div>
      <Switch>
        {/* <Route path={match.path + '/creditList'} component={CreditStatisticsList} /> */}
        <Route path={match.path + '/detiall'} component={CreditStatisticsDetiall} />
        <Route component={CreditStatisticsList} />
      </Switch>
    </div>
  )
}

export default CreditStatistics