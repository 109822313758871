/**
 * 模块名称: 客户详情页
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Drawer,
  Spin,
  message,
  Menu,
  Tabs,
  Dropdown
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import VendorInfoList from './module/VendorInfolist'
import DiscountList from './module/DiscountList'
import ContractList from './module/ContractList'
import PaybackList from './module/PaybackList'
import { Link } from 'react-router-dom'
import VendorBatch from './module/VendorBatch'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'


const CsrDetail = (props) => {
  const { location, history } = props
  const search = parseSearch(location.search)
  const [detailLoading, setDetailLoading] = useState(true)
  const [detailData, setDetailData] = useState({})
  const [headData, setHeadData] = useState({}) // 客户信息
  const [headDataList, setHeadDataList] = useState([]) // 客户信息

  const [drawerName, setDrawerName] = useState('')
  const [paybackVisible, setPaybackVisible] = useState(false)

  const [vendorBatchVisible, setVendorBatchVisible] = useState(false) // 厂商id批量转移
  const [batchLoading, setBatchLoading] = useState(false) // 厂商id批量转移Loading
  const [authList, setAuthList] = useState([])

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
  
    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      setDetailLoading(false)
      api.getCusTomInfo({ relationId: search.id, relationType: 0 }, true).then(data => {
        setDetailData(data)
      })
      getHeadData()
      api.getPageAuth().then(list => setAuthList(list))
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  // 公共部分信息
  const getHeadData = () => {
    api.getfortuneSelfFund({ relationId: search.id, relationType: 0 }).then(data => {
      setHeadData(data)
      setHeadDataList(data.list)
    }).catch()
  }

  const onMessage = () => {
    message.error("该客户未实名认证，请点申请认证通过后再发起该操作！")
  }
  
  //申请
  const onEdit0 = async (date) => {
    //0直销,1代理商
    //长期传0， 临时传1
    if (+search.status === 0) {
      onMessage()
      return
    } 
    history.push('/account/creditapply/add?customerType=0&customerId=' + date.relationId)
    return false
  }

  //申请
  // const onEdit1 = async (date) => {
  //   //0直销,1代理商
  //   //长期传0， 临时传1
  //   history.push('/account/creditapply/add?customerType=0&customerId='+date.relationId+'&accountType=1')
  //   return false
  // }

  //跳转直销订单申请订单  直销新开点击/厂商ID点击续费按钮 申请需要携带厂商id(id没有就直接传null)
  const goOrderDirectApply = () => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    history.push('/orderDirect/add', { relationId: search.id, vendorId: null })
  }

  // 申请合同
  const onContractapply = () => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    history.push(`/protocol/contract/apply?clientId=${search.id}`)
  }

  // 申请发票
  const goInvoice = () => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    history.push('/protocol/invoice/apply', { oaId: search.id })
  }


  const { TabPane } = Tabs;

  const onChangeTab = (key) => {
    if (key === '2') { // 审批中订单
      history.push('/orderDirect/auditList?oaid=' + search.id)
    } else if (key === '3') { // 已完成订单
      history.push('/orderDirect/finishedList?oaid=' + search.id)
    } else if (key === '4') { // 正常充值
      history.push('/fund/moneyClaim?relationType=0&relationId=' + search.id)
    } else if (key === '5') { // 退款信息
      history.push('/fund/refundManage?relationType=0&relationId=' + search.id)
    } else if (key === '6') { // 转款转出
      history.push('/fund/moneyTransmit?fromRelationType=0&fromRelationId=' + search.id)
    } else if (key === '7') { // 转款转入
      history.push('/fund/moneyTransmit?toRelationType=0&toRelationId=' + search.id)
    } 
  }

  // 厂商id批量转移
  const onVendorBatch = () => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    // setVendorBatchVisible(true)
    history.push('/csr/customer/newVendor', { muilt: 1, clientId: headData.oaId, client: headData.clientName}) 
  }

  // 点击认款
  const onMoneyClaim = () => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    history.push(`/fund/moneyClaim/detail?clientId=${search.id}&clientType=0`)
  }

  // 点击转款
  const onMoneyTransmit = () => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    history.push(`/fund/moneyTransmit/add?clientId=${search.id}&clientType=0`)
  }

  //退回列表页
	const onGoBack = () => {
		history.push({pathname:'/csr/customer',state: { goBack: 1}})
	}

  // 点击退款
  // const onRefundManage = () => {
  //   if (+search.status === 0) {
  //     onMessage()
  //     return
  //   } 
  //   history.push(`/fund/refundManage/add?relationId=${search.id}&relationType=0`)
  // }

  // 点击退款  权签
  const handleMenuClick = (e) => {
    if (+search.status === 0) {
      onMessage()
      return
    } 
    if (e.key == '1') {
      api.setRefundCheckApply({ refundType: 1 }).then(data => {
        history.push(`/fund/refundManage/addNot?relationId=${search.id}&relationType=0`)
      })
    } else {
      api.setRefundCheckApply({ refundType: 0 }).then(data => {
        history.push(`/fund/refundManage/add?relationId=${search.id}&relationType=0`)
      })
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='1'>
        未认款退款
      </Menu.Item>
      <Menu.Item key='2'>
        正常退款
        </Menu.Item>
    </Menu>
  )

  return (
    <Spin spinning={detailLoading}>
      <div className="pro-detail">
        {
          search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: '20px',
            top: '-20px'
          }}>返回上一页</Button>
        }
        <div style={{ paddingBottom: 20 }}>
          <h4>{`${headData.clientName ? headData.clientName: ''} (OAID: ${headData.oaId ?headData.oaId: ''})`}</h4>
          <div className="headData-Balance">
            {
              headDataList && headDataList.map((item, index) =>
                <div style={{ width: '30%', marginBottom: 5 }} key={index}>
                  <span>{item.name}: </span>
                  <span>{item.balance}</span>
                </div>)}
          </div>
        </div>
        <div className="line-box"></div>
      </div>

      {/* 按钮 */}
      <div>
        <Auth auths={authList} code="claim-money">
          {/* <Link to={`/fund/moneyClaim/detail?clientId=${search.id}&clientType=0`}> */}
            <Button className="detailSty" onClick={debounce(() => {onMoneyClaim()}, 1000)} type="primary">认款</Button>
          {/* </Link> */}
        </Auth>
        <Auth auths={authList} code="refund-money">
          {/* <Link to={`/fund/refundManage/add?relationId=${search.id}&relationType=0`}>   */}
          {/* 权签 */}
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="primary" className="detailSty">退款</Button>
          </Dropdown>
            {/* <Button className="detailSty"  onClick={debounce(() => {onRefundManage()}, 1000)} type="primary">退款</Button> */}
          {/* </Link> */}
        </Auth>
        <Auth auths={authList} code="transmit-money">
          {/* <Link to={`/fund/moneyTransmit/add?clientId=${search.id}&clientType=0`}> */}
            <Button className="detailSty" onClick={debounce(() => {onMoneyTransmit()}, 1000)} type="primary">转款</Button>
          {/* </Link> */}
        </Auth>
        <Auth auths={authList} code="apply-invoice">
          <Button className="detailSty" onClick={debounce(() => {goInvoice()}, 1000)} type="primary">申请发票</Button>
        </Auth>
        <Auth auths={authList} code="credit-limit-application">
          <Button className="detailSty" onClick={debounce(() => onEdit0(detailData), 1000)} type="primary">信用额度申请</Button>
        </Auth>
        <Auth auths={authList} code="batch-vendor-id-transfer">
          <Button className="detailSty" type="primary" onClick={debounce(() => {onVendorBatch()}, 1000)}>批量厂商ID转移</Button>
        </Auth>
        <Auth auths={authList} code="new-order">
          <Button className="detailSty" onClick={debounce(() => {goOrderDirectApply()}, 1000)} type="primary">新开订单</Button>
        </Auth>
        <Auth auths={authList} code="apply-contract">
          {/* <Link to={`/protocol/contract/apply?clientId=${search.id}`}> */}
              <Button className="detailSty" onClick={debounce(() => {onContractapply()}, 1000)} type="primary">申请合同</Button>
          {/* </Link> */}
        </Auth>
        <Auth auths={authList} code="apply-certification">
          <Link to={`/csr/customer/applyTo?clientId=${search.id}`}>
            <Button className="detailSty" type="primary">申请认证</Button>
          </Link>
        </Auth>
      </div>
      <div className="line-box"></div>
      <Drawer
        title={drawerName}
        width={1000}
        onClose={() => setPaybackVisible(false)}
        visible={paybackVisible}
        destroyOnClose={true}
      >
        <PaybackList drawerName={drawerName}></PaybackList>
      </Drawer>
      <Tabs defaultActiveKey="1" onChange={onChangeTab}>
        <TabPane tab="厂商信息列表" key="1">
          <VendorInfoList id={search.id} history={history} location={location} />
        </TabPane>
        <TabPane tab="审批中订单" key="2"></TabPane>
        <TabPane tab="已完成订单" key="3"></TabPane>
        <TabPane tab="正常充值" key="4"></TabPane>
        <TabPane tab="退款信息" key="5"></TabPane>
        <TabPane tab="转款（转出）" key="6"> </TabPane>
        <TabPane tab="转款（转入）" key="7"></TabPane>
        <TabPane tab="折扣列表" key="8">
          <DiscountList id={search.id} location={location} />
        </TabPane>
        <TabPane tab="合同列表" key="9">
          {<ContractList id={search.id} location={location} />}
        </TabPane>
      </Tabs>
      <Drawer
        title="厂商ID转出"
        width={880}
        onClose={() => setVendorBatchVisible(false)}
        visible={vendorBatchVisible}
        destroyOnClose={true}
      >
        <Spin spinning={batchLoading}>
          <VendorBatch 
            setVendorBatchVisible={setVendorBatchVisible}
            setBatchLoading={setBatchLoading}
            oaIdVal={search.id}
            historyVal={history}
            />
        </Spin>
      </Drawer>
    </Spin>
  )
}

export default Form.create()(CsrDetail)