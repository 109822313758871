/**
 * 模块名称: 转户详情与审批详情页
 * @author wangyunhai@372163.com 
 */

import React, { useState, useEffect ,useRef} from 'react'
import {
    Descriptions,
    Input,
    Table,
    Icon,
    Alert,
    Upload,
    message,
    Form,
    Button,
    Row,
    Col
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'
const FormItem = Form.Item

const { Dragger } = Upload;

const dItem = Descriptions.item
const { Column } = Table
// let pageSize = 5
let pageSizeC = 10
let pageSizeI = 10
let pageSizeA = 10
let pageSizeS = 10

let currentPageC = 1
let currentPageI = 1
let currentPageA = 1
let currentPageS = 1
let selectedIds = ''
let seachData = {
    name: '',
    id: '',
}

const AddCustomerDetail = (props) => {
    const { match, location, history } = props
    const { getFieldDecorator, validateFields } = props.form
    const [detailDataIn, setDetailDataI] = useState({})
    const [detailDataOut, setDetailDataO] = useState({})
    const [clientList, setClientList] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [acBalance, setAcBalance] = useState([])
    const [acArrears, setAcArrears] = useState([])
    const [rejectInfo, setRejectInfo] = useState([])

    const [countC, setClientC] = useState(0)
    const [countI, setCountI] = useState(0)
    const [countA, setCountA] = useState(0)
    const [countS, setCountS] = useState(0)

    const search = parseSearch(location.search)
    const [checked, setChecked] = useState(0)
    const [checkedIdArr, setCheckedIdArr] = useState([])
    const [fileList, setFileList] = useState([])
    const draggerRef = useRef(null)
    const [saveLoading, setSaveLoading] = useState(false)

    const [inId, setInId] = useState('')
    const [inName, setInName] = useState('')
    const [transferType, setTransfer] = useState('')

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        }
    }

    useEffect(() => {
        pageSizeC = 10
        pageSizeI = 10
        pageSizeA = 10
        pageSizeS = 10

        currentPageC = 1
        currentPageI = 1
        currentPageA = 1
        currentPageS = 1
        seachData = {
            name: '',
            id: '',
        }
        if (search.apid){
            getRejectedData()
            getClientList()
        }else{
            api.TransferApply({ inId: search.id, type: 0 }, true).then(data => {
                if (search.msgId) {
                    api.setMessageReaded({ ids: [search.msgId] })
                }
                setDetailDataI(data.in)
                setDetailDataO(data.out)
                setChecked(0)
                setCheckedIdArr([])
                setInId(data.in.inId)
                setInName(data.in.name)
                setTransfer(data.in.transferType)
            })
            getClientList()
       }

    }, [])

    // 文件上传 
    const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .pdf, .jpg, .jpeg, .png',
		allowSizeType: 2
	})

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setChecked(selectedRowKeys.length)
            selectedIds = selectedRowKeys
            // if (selectedIds.length > 0) {
            //     console.log(66666)
            //     getInvoiceList()
            //     getAccountList()
            //     getAcArrearsList()
            // } else {
            //     setInvoiceList([])
            //     setAcBalance([])
            //     setAcArrears([])
            //     setCountI(0)
            //     setCountA(0)
            //     setCountS(0)
            // }
        },
        onSelect: (record, selected, selectedRows) => {
            // console.log(record, selected, selectedRows)
            //let arr = []
            if (!selected) {
                for (let i = 0; i < checkedIdArr.length; i++) {
                    if (checkedIdArr[i] == record.id) {//eslint-disable-line
                        checkedIdArr.splice(i, 1)
                        i--
                    }
                }
            } else {
                checkedIdArr.push(record.id)
            }
            let arrNew = [...new Set(checkedIdArr)]
            setCheckedIdArr(arrNew)
            setChecked(arrNew.length)
            selectedIds = arrNew
            if (selectedIds.length > 0) {
                console.log(5555555)
                getInvoiceList()
                getAccountList()
                getAcArrearsList()
            } else {
                setInvoiceList([])
                setAcBalance([])
                setAcArrears([])
                setCountI(0)
                setCountA(0)
                setCountS(0)
            }
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
        //全选一列（默认十条）
        onSelectAll: (selected, selectedRows, changeRows) => {
            // console.log(checkedIdArr)
            //console.log(selected, selectedRows, changeRows)
            let arr = []
            changeRows.forEach(item => {
                arr.push(item.id)
            })
            if (selected) {
                setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
            } else {
                setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
            }
            // setChecked(arr.length)
            // selectedIds = arr
            if (selectedIds.length > 0){
                console.log(777777777)
                getInvoiceList()
                getAccountList()
                getAcArrearsList()
            }else{
                setInvoiceList([])
                setAcBalance([])
                setAcArrears([])
                setCountI(0)
                setCountA(0)
                setCountS(0)
            }
        },
        selectedRowKeys: checkedIdArr
    }
    //驳回数据
    const getRejectedData = () =>{
        api.getTransferDetail({ apid: search.apid },true).then(data => {
            let cids = '';
            if (data.oa_qd_ids !==null){
                cids = data.oa_qd_ids.split(',').map(Number)
            }
            setCheckedIdArr(cids)
            selectedIds = cids
            setInId(data.inId)
            setInName(data.inName)
            setTransfer(data.transferType)
            let attrUrl = JSON.parse(data.attachmentUrl)
            attrUrl.forEach((item, index) => {
                fileList.push({
                    status: 'done',
                    uid: index,
                    name: item.name,
                    url: item.url
                })
            })
            setChecked(cids.length)
            setRejectInfo(data)
            console.log(8888888888)
            getInvoiceList()
            getAccountList()
            getAcArrearsList()
        })
    }
    const onCancel = () => {
        history.goBack()
        // history.push({
        // 	pathname:'/setSystem/helpCenterMgt',
        // 	state: { 'tabActiveKey': '1' }
        // })
    }
    //提交
    const formSubmit = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (selectedIds == '') {
                message.info("请选择客户")
                return
            }
            if (!err) {
                setSaveLoading(true)
                api.submitApply({
                    id: search.apid ? search.apid :'',
                    oa_qd_ids: selectedIds,
                    inId: inId,
                    inName: inName,
                    attachmentUrl: fileList,
                    remark: vals.remark,
                    transferType: transferType
                })
                    .then(() => {
                        setSaveLoading(false)
                        message.success('操作成功')
                        setTimeout(() => {
                            history.push('/csr/turnAudit')
                        }, 1000);
                    })
                    .catch(() => setSaveLoading(false))
            }
        })
    }
    //反选
    const onReverse = () => {
        let IDS = JSON.parse(localStorage.getItem('ids'))
        let arr = []
        for (let i = 0; i < IDS.length; i++) {
            arr[i] = IDS[i].id
        }
        let filterIds = []
        arr.forEach(item => {
            if (!checkedIdArr.includes(item)) {
                filterIds.push(item)
            }
        })
        setCheckedIdArr(filterIds)
        setChecked(filterIds.length)
        selectedIds = filterIds
        if (selectedIds.length > 0) {
            console.log(11111)
            getInvoiceList()
            getAccountList()
            getAcArrearsList()
        } else {
            setInvoiceList([])
            setAcBalance([])
            setAcArrears([])
            setCountI(0)
            setCountA(0)
            setCountS(0)
        }
    }
    //全选
    const onAllelection = () => {
        let IDS = JSON.parse(localStorage.getItem('ids'))
        let arr = []
        for (let i = 0; i < IDS.length; i++) {
            arr[i] = IDS[i].id
        }
        setChecked(arr.length)
        setCheckedIdArr(arr)
        selectedIds = arr
        if (selectedIds.length > 0) {
            console.log(222222)
            getInvoiceList()
            getAccountList()
            getAcArrearsList()
        } else {
            setInvoiceList([])
            setAcBalance([])
            setAcArrears([])
            setCountI(0)
            setCountA(0)
            setCountS(0)
        }
    }
    //客户信息
    const getClientList = () => {
        api.getStaffForClientList({
            limit: pageSizeC, 
            page: currentPageC,
            name: seachData.name,
            id: seachData.id
        }, true).then(data => {
            setClientList(data.list)
            setClientC(data.count)
            if (currentPageC === 1) {
                if (data.allIds) {
                    localStorage.setItem('ids', JSON.stringify(data.allIds))
                } else {
                    localStorage.setItem('ids', JSON.stringify([]))
                }
            }
        })
    }
    const onChangeClientTable = (pagination, filters, sorter) => {
        currentPageC = pagination.current
        pageSizeC = pagination.pageSize
        getClientList()
    }


    //未收款发票
    const getInvoiceList = () => {
        console.log('开始')
        api.getInvoiceInfo({ ids: selectedIds, getQuery: { limit: pageSizeI, page: currentPageI }}).then(data => {
            setInvoiceList(data.list)
            setCountI(data.count)
        })
    }
    const onChangeInvoiceTable = (pagination, filters, sorter) => {
        currentPageI = pagination.current
        pageSizeI = pagination.pageSize
        console.log(33333)
        getInvoiceList()
    }

    //帐户余额
    const getAccountList = () => {
        api.getAccountInfo({ clientIds: selectedIds, getQuery: { limit: pageSizeA, page: currentPageA } }).then(data => {
            setAcBalance(data.list)
            setCountA(data.count)
        })
    }
    const onChangeAccountTable = (pagination, filters, sorter) => {
        currentPageA = pagination.current
        pageSizeA = pagination.pageSize
        getAccountList()
    }


    //信用帐户欠款
    const getAcArrearsList = () => {
        api.getAcArrearsInfo({ clientIds: selectedIds, getQuery: { limit: pageSizeS, page: currentPageS } }).then(data => {
            setAcArrears(data.list)
            setCountS(data.count)
        })
    }
    const onChangeAcArrearsTable = (pagination, filters, sorter) => {
        currentPageS = pagination.current
        pageSizeS = pagination.pageSize
        getAcArrearsList()
    }

    // 筛选
    const nameInp = useRef(null)
    const idInp = useRef(null)
    const onSearch = () => {
        seachData.name = nameInp.current.value
        seachData.id = idInp.current.value
        currentPageC = 1
        getClientList()
    }
    
    const onReset = () => {
        seachData.name = ''
        nameInp.current.value = ''
        seachData.id = ''
        idInp.current.value = ''
        currentPageC = 1
        getClientList()
    }
    return (
        <>
            <div className="pro-detail">
                {/* <div className="line-box"></div> */}
                <Descriptions title="员工信息" layout="vertical" className="pro-detail-smailtitle pro-apply" column={4} >
                    <dItem label="转出人">
                        {detailDataOut.name ? detailDataOut.name : rejectInfo.outName}
                    </dItem>
                    <dItem label="员工类型">
                        {detailDataOut.stfType ? detailDataOut.stfType :rejectInfo.outTransferType}
                    </dItem>
                    <dItem label="直属上级">
                        {detailDataOut.leaderName ? detailDataOut.leaderName : rejectInfo.outLeader}
                    </dItem>
                    <dItem label="所属部门">
                        {detailDataOut.departmentName ? detailDataOut.departmentName : rejectInfo.outDepartment}
                    </dItem>
                    <dItem label="转入人">
                        {detailDataIn.name ? detailDataIn.name : rejectInfo.inName}
                    </dItem>
                    <dItem label="员工类型">
                        {detailDataIn.stfType ? detailDataIn.stfType : rejectInfo.transferTypeName}
                    </dItem>
                    <dItem label="直属上级">
                        {detailDataIn.leaderName ? detailDataIn.leaderName : rejectInfo.inLeader}
                    </dItem>
                    <dItem label="所属部门">
                        {detailDataIn.departmentName ? detailDataIn.departmentName : rejectInfo.inDepartment}
                    </dItem>
                </Descriptions>
                <div className="line-box"></div>
                <Descriptions
                    title="客户信息"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                {/* 客户信息筛选 */}
                <Form {...formItemLayout}>
                    <Row gutter={24}>
                        <Col span={10}>
                            <FormItem label="客户名称">
                                <input className="ant-input" ref={nameInp} type="title" placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="OAID">
                                <input className="ant-input" ref={idInp} type="title" placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={6} style={{ marginTop: 5 }}>
                            <Button style={{ marginRight: 5 }} onClick={onReset}>重置</Button>
                            <Button type="primary" htmlType="submit" onClick={onSearch}>查询</Button>
                        </Col>
                    </Row>
                </Form>
                {/* <Alert className="corp-count" message={`总计：${countC}条数据`} type="info" showIcon /> */}
                <Alert className="corp-count" message={
                    <>
                        {`总计：${countC}条数据`}
                        <span> 已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
                        {checked !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
                        {checked == 0 //eslint-disable-line
                            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
                        <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>
                    </>
                } type="info" showIcon />

                <Table
                    dataSource={clientList} rowKey="id" className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeC,
                        total: countC,
                        current: currentPageC,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeClientTable}
                    rowSelection={rowSelection}

                >
                    <Column title="客户名称" dataIndex="name" />
                    <Column title="OAID" dataIndex="id" />
                    <Column title="创建时间" dataIndex="addTime" />
                    <Column title="帐户总余额" dataIndex="remainMoney" />
                    <Column title="信用帐户欠款" dataIndex="debtMoney" />
                </Table>

                <Descriptions
                    title="未收款发票"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countI}条数据`} type="info" showIcon />
                <Table
                    dataSource={invoiceList}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize:pageSizeI,
                        total: countI,
                        current: currentPageI,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeInvoiceTable}
                >
                    <Column title="OA客户名称" dataIndex="customerName" />
                    <Column title="OAID" dataIndex="customerId" />
                    <Column title="发票抬头" dataIndex="invtitle" />
                    <Column title="发票金额" dataIndex="money" />
                    <Column title="发票类型" dataIndex="invtypeName" />
                    <Column title="发票号" dataIndex="invoiceNumber" />
                    <Column title="发票状态" dataIndex="InvoiceStatusName" />
                    <Column title="收款状态" dataIndex="collestatusName" />
                    <Column title="申请人" dataIndex="applyName" />
                    <Column title="申请时间" dataIndex="applyDate" />
                </Table>
                <Descriptions
                    title="帐户余额"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countA}条数据`} type="info" showIcon />
                <Table
                    dataSource={acBalance}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeA,
                        total: countA,
                        current: currentPageA,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeAccountTable}
                >
                    <Column title="帐户编号" dataIndex="accountNo" />
                    <Column title="OAID" dataIndex="relationId" />
                    <Column title="OA客户名称" dataIndex="name" />
                    <Column title="	帐户" dataIndex="companyName" />
                    <Column title="创建时间" dataIndex="addTime" />
                    <Column title="帐户余额" dataIndex="balance" />
                </Table>
                <Descriptions
                    title="信用帐户欠款"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countS}条数据`} type="info" showIcon />
                <Table
                    dataSource={acArrears}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeS,
                        total: countS,
                        current: currentPageS,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeAcArrearsTable}
                >
                    <Column title="OAID" dataIndex="oaqId" />
                    <Column title="客户名称" dataIndex="clientName" />
                    <Column title="帐户" dataIndex="company" />
                    <Column title="帐户类型" dataIndex="accountTypeName" />
                    <Column title="已用额度" dataIndex="usedAmount" />
                    <Column title="到期还款日" dataIndex="endDay" />
                    <Column title="申请人" dataIndex="proposerName" />
                </Table>
                <div className="line-box"></div>
                <Form onSubmit={formSubmit} {...formItemLayout}>
                <div>
                    <h4 style={{ marginTop: 12 }}>其他信息</h4>
                    <>
                        <FormItem label="附件">
                            <div className="dragger-box" ref={draggerRef}>
                                <Dragger {...uploadFiles}>
                                    <p className="ant-upload-drag-icon"> <Icon type="inbox" /></p>
                                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                    <p className="ant-upload-hint">附件支持格式：.rar, .zip, .pdf, .jpg, .jpeg, .png 支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。</p>
                                </Dragger>
                            </div>
                        </FormItem>
                            <FormItem label="备注">
                                {getFieldDecorator('remark', { initialValue: rejectInfo.remark })(
                                <Input.TextArea placeholder="请输入备注" maxLength={100} />
                            )}
                        </FormItem>
                    </>
                </div>
                <div style={{ paddingRight: 50 }}>
                    <BtnGroup onCancel={onCancel} confirmName='提交' loading={saveLoading} right />
                </div>
                 </Form>
            </div>
        </>
    )
}

export default Form.create()(AddCustomerDetail)