/**
 * 模块名称: 批量转户抽屉
 * @author James
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Button,
  message,
} from 'antd'
import { Link } from 'react-router-dom'
import {useSelector} from "react-redux";

const { Column } = Table

let filter = {
  name: '',
}
let pageSize = 10
let currentPage = 1

const BatchTransfers = (props) => {
  // const { match, location, history } = props
  const { setTransferVisible, setTransferLoading } = props
  const [staffList, setStaffList] = useState([])
  const [stfId, setStfId] = useState('')
  const [flag, setFlag] = useState(true)
  const [count, setCount] = useState(0)
  const userInfo = useSelector(state => state.userInfo)
  useEffect(() => {
    filter = {
      name: '',
    }
    // getVendorList()
  }, [])

  const getStaffList = () => {
    setTransferLoading(true)
    api.getTransferStaffInfo({ name: filter.name, limit: pageSize, page: currentPage})
      .then(data => {
        setTransferLoading(false)
        setStaffList(data.list)
        setCount(data.count)
      })
      .catch(() => setTransferLoading(false))
  }
  // const [selectedRowKeys, selectedRowKey] = useState([]);
  const departInp = useRef(null)

  //搜索姓名
  const onSearch = () => {
    filter.name = departInp.current.value
    if (departInp.current.value == '') {
      message.info("请输入员工姓名")
      return
    }
    setStfId('')
    setFlag(true)
    getStaffList()
  }

  //获取单选种的值
  const onSelectChange=(selectedRowKeys)=> {
    if (+userInfo.id === +selectedRowKeys[0]) {
      setFlag(true)
      return
    }
    setStfId(selectedRowKeys[0])
    setFlag(false)
  }
  //分页
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    getStaffList()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
  
    <>
      <div className="vendorstry">
        <div className="vendorwrap">
          <div className="vendor-item">
            <div style={{ width: 220 }}>请选择转入员工姓名: </div>
            <input className="ant-input" ref={departInp} type="text" placeholder="请输入员工姓名" onKeyUp={onKeyup} />
          </div>
          <Button onClick={onSearch} type="primary">查询</Button>
        </div>
        <Table
          dataSource={staffList}
          rowKey="id"
          className="table-page"
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
          rowSelection={{onChange:onSelectChange,type:'radio'}}
        >
          <Column title="员工id" dataIndex="id" />
          <Column title="员工姓名" dataIndex="staffName" />
          <Column title="职位" dataIndex="jobPosition" />
          <Column title="所属部门" dataIndex="departmentName" />
          <Column title="直属上级" dataIndex="leaderName" />          
        </Table>
        <div style={{ height: 60 }}></div>
        <div className="setVendorstryBtn">
          <Link to={`/csr/turnAudit/apply?id=${stfId}`}>
             <Button disabled= {flag} type="primary" style={{ margin: 20 }}>下一步</Button>
          </Link>
          <Button type="primary" onClick={() => { setTransferVisible(false) }}>关闭</Button>
        </div>
      </div>
    </>
  )
}

export default BatchTransfers