/**
 * 模块名称: 部门管理
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  AutoComplete,
  Tooltip
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import CorpFilter from '@/components/CorpFilter'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'
import ProjectsList from "./ProjectsList"

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let filter = {
  name: '',//部门名称
  departmentType:'',//部门类型
  companyId: '',//所属公司
  groupId:'',//所属事业群
  leaderName:''//部门负责人
}

let tmpProjectVals = []
let projectAreaIsValidate = 1;

const Department = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [showProjectDrawer, setShowProjectDrawer] = useState(false)
  const [projectDrawerTitle, setProjectDrawerTitle] = useState('')
  const [projectDrawerValue, setProjectDrawerValue] = useState([])
  const [projectsList, setProjectList] = useState([])
  const [setProjectsType,setSetProjectsType] = useState(0)
  const [setPorjectsDeaptId,setSetPorjectsDeaptId] = useState(0)
  const [validated, setValidated] = useState(false)
  const [selectData, setSelectData] = useState({
    departList: [],
    corpLst: [],
    groupList: [],
    leaderList: [],
    isRequest: false
  })

  const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      name: '',
      companyId: ''
    }
    getDepartment()
    api.getPageAuth().then(list => setAuthList(list))
    getSelectData()
  }, [])

  const getDepartment = () => {
    setLoading(true)
    api.getDepartment({
      ...filter,
      limit: pageSize,
      page: currentPage,
      needAuth: 1
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getCommon({ type: 'department_type' }),
      api.getSelectCompanyList(),
      api.getSelectGroupList({ limit: global.paramsLimit }),
      api.getLeaderList({ limit: global.paramsLimit, minJobPosition: 4 })
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        departList: list[0],
        corpLst: list[1].list,
        groupList: list[2].list,
        leaderList: list[3].list,
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }




  // 提交添加
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        // console.log('submit vals', vals)
        setSaveLoading(true)
        api.saveDepartment(vals).then(() => {
          setSaveLoading(false)
          setVisible(false)
          message.success('添加成功')
          getDepartment()
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const onDelete = (id) => {
    api.delDepartment({ id }, true).then(() => {
      message.success('删除成功')
      getDepartment()
    })
  }

  const onEdit = (data) => {
    setVisible(true)
    editId = data.id
    if (selectData.isRequest) {
      setFieldsValue({
        name: data.name,
        departmentType: +data.departmentType,
        groupId: data.groupId === '' ? '' : +data.groupId,
        companyId: +data.companyId,
        leaderId: data.leaderId
      })
    } else {
      getSelectData().then(() => {
        setFieldsValue({
          name: data.name,
          departmentType: +data.departmentType,
          groupId: data.groupId === '' ? '' : +data.groupId,
          companyId: +data.companyId,
          leaderId: data.leaderId
        })
      })
    }
  }

  // 添加部门
  const onAddCorp = () => {
    editId = ''
    setVisible(true)
    resetFields()
    if (!selectData.isRequest) {
      getSelectData()
    }
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getDepartment()
  }

  const onSort = (id, flow) => {
    api.updateDepartmentSort({ id, flow }).then(getDepartment)
  }

  const onChangeCorp = (data) => {
    // console.log('onChangeCorp', data)
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getDepartment()
  }

  const onChangeFilterDepartName = (value) => {
    filter.name = value
  }
  const onChangeFilterDepartTypeList = (value) => {
    filter.departmentType = value
  }
  const onChangeFilterGroupList = (value) => {
    filter.groupId = value
  }
  const onChangeFilterLeaderList = (value) => {
    filter.leaderName = value
  }
  const departInp = useRef(null)
  const onSearch = () => {
    filter.name = departInp.current.value
    history.replace(match.path)
    currentPage = 1
    getDepartment()
  }

  const onReset = () => {
    filter.name = ''
    filter.departmentType = ''
    filter.groupId = ''
    filter.leaderName = ''
    departInp.current.value = ''
    setFieldsValue({
      filterDepartmentName:'',
      filterDepartmentType:'',
      filterFilterGroup:'',
      filterFilterLeader:'',

    })
    history.replace(match.path)
    currentPage = 1
    getDepartment()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }


  // 选择审批公司
  const onSelectedProjects = data => {
    tmpProjectVals = data
  }


  const onOpenProjects = (departData, type) => {
    api.getCompanyinfo({id: departData.companyId} ).then((data) => {
      openProjects(data, departData, type)
    })
  }

  const openProjects = (data, departData, type) => {
    setProjectList(data.projectRangeList);
    setSetProjectsType(type)
    setSetPorjectsDeaptId(departData.id)

    if(type == 1){
      setProjectDrawerTitle("设置主项")
      setProjectDrawerValue(departData.mainProjectList)

      tmpProjectVals = departData.mainProjectList
    }else{
      setProjectDrawerTitle("设置项目范围")
      setProjectDrawerValue(departData.projectAreaList)
      setValidated(departData.projectAreaIsValidate)

      tmpProjectVals = departData.projectAreaList
      projectAreaIsValidate = departData.projectAreaIsValidate
    }
    setShowProjectDrawer(true)
  }

  const onSaveProjectsRange = () => {
    setformLoading(true)
    if(setProjectsType == 1){
      api.setMainProjects({id:setPorjectsDeaptId, mainProjects:tmpProjectVals.map(item => item.id)})
          .then((data)=>{
            saveAfter()
          })
    }else if(setProjectsType == 2){
      api.setProjectArea({id:setPorjectsDeaptId, projectArea:tmpProjectVals.map(item => item.id), projectAreaIsValidate})
          .then((data)=>{
            saveAfter()
          })
    }
  }

  const saveAfter = () => {
    setformLoading(false)
    if(setProjectsType == 1){
      setList(list.map(item => setPorjectsDeaptId === item.id ? {...item, mainProjectList:tmpProjectVals} : item))
    }else if(setProjectsType == 2){
      const theList = list.map(item => setPorjectsDeaptId === item.id ? {...item, projectAreaList:tmpProjectVals, projectAreaIsValidate:projectAreaIsValidate} : item)
      setList(theList)
    }

    setShowProjectDrawer(false)
  }

  const updateValidate = (validate) => {
    projectAreaIsValidate = validate ? 1 : 0;
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="filter-wrap">
        <h4 className="title">其它选项：</h4>
      </div>
      <div className="filter-wrap">
        {/* <br /> */}
        <div className="filter-item">
          <label htmlFor="">部门名称</label>
          {getFieldDecorator('filterDepartmentName')(
          <input
              placeholder="请输入"
              className="ant-input"
              style={{ width: 150 }} ref={departInp}
              onChange={onChangeFilterDepartName}
              onKeyUp={onKeyup}
              type="text" />
          )}
        </div>

        <div className="filter-item">
          <label htmlFor="">部门类型</label>
          {getFieldDecorator('filterDepartmentType')(
          <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="请选择部门类型"
              style={{ width: 180 }}
              onChange={onChangeFilterDepartTypeList}
          >
            <Option value="">全部</Option>
            {selectData.departList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
          )}
        </div>
        <div className="filter-item">
          <label htmlFor="">所属事业群</label>
          {getFieldDecorator('filterFilterGroup')(
          <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="请选择所属事业群"
              onChange={onChangeFilterGroupList}
              style={{ width: 180 }}
              dropdownMatchSelectWidth={false}
          >
            <Option value="">全部</Option>
            {selectData.groupList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
          )}
        </div>
        <div className="filter-item">
          <label htmlFor="">部门负责人</label>
          {getFieldDecorator('filterFilterLeader')(
          <AutoComplete
              style={{ width: 150 }}
              onChange={onChangeFilterLeaderList}
              dataSource={selectData.leaderList.map(item =>item.staffName)}
              placeholder="请输入"
              filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          />
          )}
        </div>
        
      </div>
      <div className="filter-wrap" style={{paddingBottom: 10}}>
        <BtnGroup style={{marginLeft: '85%'}} cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="add">
          <Button type="primary" icon="plus" onClick={onAddCorp}>添加</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2500 }}
        // scroll={{x: 'max-content'}}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" fixed="left" width={100} />
        <Column title="部门名称" dataIndex="name" fixed="left" width={160} />
        <Column title="部门类型" dataIndex="departmentTypeCn" width={200} />
        <Column title="所属公司" dataIndex="companyName" />
        <Column title="所属事业群" dataIndex="companyGroupName" />
        <Column title="部门全称" key="fullName" render={(text, record) => `${text.companyName} ${text.name}`} />
        <Column title="部门负责人" dataIndex="leaderName" width={200} />
        <Column
          title="主项"
          key="main-project"
          render={(text, record) => {
            // console.log(record)
            if( ![1,2,"1","2"].includes(record.departmentType) ){
               return (
                   <span>--</span>
               )
            }


            if (!Array.isArray(record.operateAuth)) {
              console.error('AuthMiddleware：数据格式错误')
              return null
            }

            if(record.operateAuth.includes("edit")){
              return (
                  <a onClick={() => onOpenProjects(record, 1)}>
                    {record.mainProjectList.length ? record.mainProjectList.map(item => item.name).join(',') : '设置主项'}
                  </a>
              )
            }else{
              return (
                  <>
                    {record.mainProjectList.length ? record.mainProjectList.map(item => item.name).join(',') : '设置主项'}
                  </>
              )
            }
          }}
        />
        <Column
            title="项目范围"
            key="project-area"
            render={(text, record) => {
              // console.log(record)
              if( ![1,4,"1","4"].includes(record.departmentType) ){
                return (
                    <span>--</span>
                )
              }

                if (!Array.isArray(record.operateAuth)) {
                    console.error('AuthMiddleware：数据格式错误')
                    return null
                }

              if(record.operateAuth.includes("edit")){
                  return (
                      <a onClick={() => onOpenProjects(record, 2)}>
                          {record.projectAreaList.length ? record.projectAreaList.map(item => item.name).join(',') : '设置项目范围'}
                      </a>
                  )
              }else{
                  return (
                      <>
                          {record.projectAreaList.length ? record.projectAreaList.map(item => item.name).join(',') : '设置项目范围'}
                      </>
                  )
              }
            }}
        />
        <Column title="排序" key="sort" width={80} render={(text, record, index) => (
          <span className="caret-wrap">
            <Icon type="caret-up" onClick={() => onSort(text.id, 'up')} />
            <Icon type="caret-down" onClick={() => onSort(text.id, 'down')} />
          </span>
        )} />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Auth auths={text.operateAuth} code="edit">
              <Tooltip title="编辑" placement="bottom">
                <Icon type="edit" className="operate-icon" style={{ color: '#1890ff' }} onClick={() => onEdit(record)} />
              </Tooltip>
            </Auth>
            {' '}
            <Auth auths={text.operateAuth} code="delete">
              <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Tooltip title="删除" placement="bottom">
                  <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                </Tooltip>
              </Popconfirm>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title="添加/编辑部门"
        width={600}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="部门名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入部门名称' }]
              })(<Input placeholder="请输入部门名称" />)}
            </FormItem>
            <FormItem label="部门类型">
              {getFieldDecorator('departmentType', {
                rules: [{ required: true, message: '请选择部门类型' }]
              })(
                <Select placeholder="请选择部门类型">
                  {selectData.departList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="所属公司">
              {getFieldDecorator('companyId', {
                rules: [{ required: true, message: '请选择所属公司' }]
              })(

                <Select placeholder="请选择所属公司"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {selectData.corpLst.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="所属事业群">
              {getFieldDecorator('groupId')(
                <Select placeholder="请选择所属事业群"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {selectData.groupList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="部门负责人">
              {getFieldDecorator('leaderId')(
                <Select placeholder="请选择部门负责人"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {selectData.leaderList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
                </Select>
              )}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>

      <Drawer
          visible={showProjectDrawer}
          width={650}
          onClose={() => setShowProjectDrawer(false)}
          title={projectDrawerTitle}
          destroyOnClose={true}
      >
        <ProjectsList onSelectedProjects={onSelectedProjects} list={projectsList} updateValidate={updateValidate} validated={validated} value={projectDrawerValue} type={setProjectsType} title="项目" />
        <BtnGroup style={{ marginTop: 40 }} onConfirm={onSaveProjectsRange} onCancel={() => setShowProjectDrawer(false)} />
      </Drawer>
    </>
  )
}

export default Form.create()(Department)