/**
 * 模块名称: 信用账户统计详情
 * @author james
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Button,
  Input,
  Select,
  Icon,
  Row,
  Col,
  Alert,
  message
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
// import CorpFilter from '@/components/CorpFilter'
import CreditVenderIdQuery from './CreditVenderIdQuery'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
// let oaId = 0

let filter = {
  accountType: '',
  projectNames: '',
  proposerName: '',
  leaderName: '',
  companyId: '',
  deptId: ''
}
let params = {}

let accountTypeList =[
  {id: 0, name: '长期垫款'},
  {id: 1, name: '临时垫款'}
]

const CreditStatisticsDetiall = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [dataVal, setDataVal] = useState({})
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const search = parseSearch(props.location.search)
  const [transferVisible, setTransferVisible] = useState(false) // 厂商id查询
  const [sproposerId, setSproposerId] = useState('') 
  const [companyId, setCompanyId] = useState('') 
  const [accountType, setAccountType] = useState('') 
  const [companyType, setCompanyType] = useState([])
  const [department, setDepartment] = useState([])
  const [oaId, setOaId] = useState('')
  const [oaName, setOaName] = useState('')
  // const [sproposerId, setSproposerId] = useState('') 

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo =CryptoJS.MD5(search.oaqId + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      // oaId = search.oaqId ? +search.oaqId : ''
      setOaId(+search.oaqId)
      setOaName(search.name)
      filter = {
        accountType: '',
        projectNames: '',
        proposerName: '',
        leaderName: '',
        companyId: '',
        deptId: ''
      }
      api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setCompanyType(data.list))
      api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list))
      getList()
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  const getList = () => {
    setLoading(true)
    api.getCreditAccountStaDetaill({ ...filter, oaqId: search.oaqId ? search.oaqId : oaId, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setDataVal(data)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        // history.replace(match.path)
        history.replace(match.path + '?oaqId=' + search.oaqId + '&name=' + search.name + '&type=' + search.type)
        // console.log("history", history)
        filter = {
          ...filter,
          ...vals,
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      accountType: '',
      projectNames: '',
      proposerName: '',
      leaderName: '',
      companyId: '',
      deptId: ''
    }
    history.replace(match.path + '?oaqId=' + search.oaqId + '&name=' + search.name + '&type=' + search.type)
    currentPage = 1
    getList()
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?oaqId=' + search.oaqId + '&name=' + search.name + + '&type=' + search.type + '&page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  // const onChangeCorp = (data) => {
  //   filter.companyId = data.map(item => item.id).join(',')
  //   history.replace(match.path)
  //   currentPage = 1
  //   getList()
  // }

  const onToggle = () => {
    setExpand(!expand)
  }
  // 厂商id查询
  const onVenderQuery = (vlue) => {
    setTransferVisible(true)
    setSproposerId(vlue.sproposerId)
    setCompanyId(vlue.companyId)
    setAccountType(vlue.accountType)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      {/* <CorpFilter onChange={onChangeCorp} /> */}
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
            <Col span={6}>
                <FormItem label="账户类型" labelCol={{ span: 8 }}>
                  {getFieldDecorator('accountType')(
                    <Select placeholder="全部">
                      {accountTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="关联项目" labelCol={{ span: 8 }}>
                  {getFieldDecorator('projectNames')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="负责人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('proposerName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="直属上级" labelCol={{ span: 8 }}>
                  {getFieldDecorator('leaderName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="所属公司" labelCol={{ span: 8 }}>
                  {getFieldDecorator('companyId')(
                    <Select 
                      placeholder="全部"
                      showSearch={true}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                    >
                    {companyType.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                  </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="所属部门" labelCol={{ span: 8 }}>
                  {getFieldDecorator('deptId')(
                    <Select 
                      placeholder="全部" 
                      showSearch={true}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                    >
                      {department.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>

      <div className="line"></div>
      <div className="creditStatistics-top">
        <div className="creditStatistics-top-title" title={oaName}>{search.type == 0 ? '客户名称' : '代理商'}: {oaName}</div>
        <div>{search.type == 0 ? 'OAID' : 'QID'}: {oaId}</div>
        <div>信用总额度: {dataVal.total}</div>
        <div>已用总额度: {dataVal.useTotal}</div>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1500 }}
        onChange={onChangeTable}
      >
        <Column title="所属公司" dataIndex="company" />
        <Column title="账户类型" dataIndex="accountTypeName" />
        <Column title="到期日" dataIndex="endDay" />
        <Column
         title="关联项目"  
         width={260}
         ellipsis={true} 
         dataIndex="projectNames" 
        />
        <Column title="信用额度" dataIndex="totalAmount" />
        <Column title="已用信用额度"
          dataIndex="usedAmount"
          render={(name, record) => <a onClick={() => onVenderQuery(record)}>{name}</a>}
        />
        <Column title="可用信用总额度" dataIndex="trueAmount" />
        <Column title="负责人" dataIndex="proposerName" />
        <Column title="所属部门" dataIndex="departmentName" />
        <Column title="直属上级" dataIndex="leaderName" />
      </Table>
      <Drawer
        title="订单列表"
        width={880}
        onClose={() => setTransferVisible(false)}
        visible={transferVisible}
        destroyOnClose={true}
      >
        <CreditVenderIdQuery 
          setTransferVisible={setTransferVisible}
          sproposerId={sproposerId}
          companyId={companyId}
          accountType={accountType}
          clientId={+search.oaqId}
        />
      </Drawer>
    </>
  )
}

export default Form.create()(CreditStatisticsDetiall)
