/**
 * 模块名称: 客户管理
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Modal,
  Spin,
  Alert,
  Menu,
  Dropdown
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import moment from 'moment'
import { Link } from 'react-router-dom'
import VendorIdQuery from './module/VendorIdQuery'
import NameQuery from './module/NameQuery'
import PaybackList from './module/PaybackList'
import CsrManagementRepair from './module/CsrManagementRepair'
import BatchTransfer from '@/pages/Customer/ClientTransfer/BatchTransfers'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  name: '',
  idType: '',
  id: '',
  verityStatus: '',
  contactName: '',
  contactMobile: '',
  contactMail: '',
  run: '',
  sale: '',
  sTime: '',
  eTime: '',
  verifyNumber: ''
}
let params = {}

const CsrManagement = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleRepair, setVisibleRepair] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [filterContent, setFilterContent] = useState([])
  const [statusVal, setStatusVal] = useState(undefined)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [authList, setAuthList] = useState([])
  const search = parseSearch(props.location.search)
  const [idTypeArr, setIdTypeArr] = useState([]) // id类型
  const [verifyStatusArr, setVerifyStatusArr] = useState([]) // 认证类型
  const [vendorVisible, setVendorVisible] = useState(false)
  const [vendorloading, setVendorLoading] = useState(false) // 厂商ID查询
  const [nameloading, setNameLoading] = useState(false)
  const [nameVisible, setNameVisible] = useState(false) // 客户名称查询
  const [drawerName, setDrawerName] = useState('')
  const [paybackVisible, setPaybackVisible] = useState(false) // 负利还款
  const [transferVisible, setTransferVisible] = useState(false) // 批量转户
  const [transferLoading, setTransferLoading] = useState(false)

  useEffect(() => {
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      filter = {
        name: '',
        idType: '',
        id: '',
        verityStatus: '',
        contactName: '',
        contactMobile: '',
        contactMail: '',
        run: '',
        sale: '',
        sTime: '',
        eTime: '',
        verifyNumber: ''
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      setTimeout(() => {
        setFieldsValue({
          name: filter.name ? filter.name : undefined,
          idType: JSON.stringify(filter.idType) ? filter.idType : undefined,
          id: filter.id ? filter.id : undefined,
          verityStatus: JSON.stringify(filter.verityStatus) ? filter.verityStatus : undefined,
          contactName: filter.contactName ? filter.contactName : undefined,
          contactMobile: filter.contactMobile ? filter.contactMobile : undefined,
          contactMail: filter.contactMail ? filter.contactMail : undefined,
          run: filter.run ? filter.run : undefined,
          sale: filter.sale ? filter.sale : undefined,
          addTime: filter.sTime ? [moment(filter.sTime, 'YYYY-MM-DD'), moment(filter.eTime, 'YYYY-MM-DD')] : undefined,
          verifyNumber: filter.verifyNumber ? filter.verifyNumber : undefined,
        })
      }, 1000)
    }
    getList()
    getClientFilterData()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  // 获取筛选条件
  const getClientFilterData = () => {
    api.getClientFilterData().then(res => {
      setIdTypeArr(res.idType)
      setVerifyStatusArr(res.verifyStatus)
    }).catch()
  }

  const getList = () => {
    setLoading(true)
    api.getFortuneClientList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          sTime: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          eTime: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      name: '',
      idType: '',
      id: '',
      verityStatus: '',
      contactName: '',
      contactMobile: '',
      contactMail: '',
      run: '',
      sale: '',
      sTime: '',
      eTime: '',
      verifyNumber: ''
    }
    setStatusVal(undefined)
    setStatusValName(undefined)
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[value].content
    setFieldsValue({
      name: data.name ? data.name : undefined,
      idType: JSON.stringify(data.idType) ? data.idType : undefined,
      id: data.id ? data.id : undefined,
      verityStatus: JSON.stringify(data.verityStatus) ? data.verityStatus : undefined,
      contactName: data.contactName ? data.contactName : undefined,
      contactMobile: data.contactMobile ? data.contactMobile : undefined,
      contactMail: data.contactMail ? data.contactMail : undefined,
      run: data.run ? data.run : undefined,
      sale: data.sale ? data.sale : undefined,
      addTime: data.addTime ? [moment(data.addTime[0], 'YYYY-MM-DD'), moment(data.addTime[1], 'YYYY-MM-DD')] : undefined,
      verifyNumber: data.verifyNumber ? data.verifyNumber : undefined
    })
  }

  // 弹出框确定
  const HandleOk = () => {
    if (filterInfoValue.trim() === '') {
      message.error('筛选名称不能为空')
      return
    }
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const handleMenuClick = (e) => {
    // console.log('click', e)
    if (e.key == '1') {
      setVendorVisible(true)
    } else if (e.key == '2') {
      setNameVisible(true)
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        authList.includes("vendor-id-query") ?
          <Menu.Item key='1'>
            厂商ID查询
      </Menu.Item> : null
      }
      {
        authList.includes("customer-name-query") ?
          <Menu.Item key='2'>
            客户名称查询
        </Menu.Item> : null
      }
      {
        authList.includes("distribution-manager") ?
          <Menu.Item key='3'>
            <Link to={`${match.path}/headSetup`}>
              分配负责人
          </Link>
          </Menu.Item> : null
      }
      {
        authList.includes("distribution-sales") ?
          <Menu.Item key='4'>
            <Link to={`${match.path}/headSetup`}>
              分配销售
          </Link>
          </Menu.Item> : null
      }
      {
        authList.includes("distribution-customer-service") ?
          <Menu.Item key='5'>
            <Link to={`${match.path}/headSetup`}>
              分配客服
          </Link>
          </Menu.Item> : null
      }
    </Menu>
  )

  // 负利
  const onPayback = () => {
    setPaybackVisible(true)
    setDrawerName('还款申请')
  }

  // 批量转户
  const onBatchTransfer = () => {
    setTransferVisible(true)
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //厂商信息修补
  const onRepairManufacturer = () => {
    setVisibleRepair(true)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo csr-management-form">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="OA客户名称" labelCol={{ span: 6 }}>
                  {getFieldDecorator('name')(<Input placeholder="请输入OA客户名称" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <div className="csrManageNo">
                  <FormItem label="ID" labelCol={{ span: 6 }}>
                    {getFieldDecorator('idType')(
                      <Select placeholder="请选择"
                        style={{ width: 100 }}
                        dropdownMatchSelectWidth={false}
                      >
                        {idTypeArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('id')(<Input className="csrManageNoTwo" placeholder="请输入" />)}
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <FormItem label="认证状态" labelCol={{ span: 6 }}>
                  {getFieldDecorator('verityStatus')(
                    <Select placeholder="全部"
                      style={{ width: 200 }}
                      dropdownMatchSelectWidth={false}
                    >
                      {verifyStatusArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="联系人姓名" labelCol={{ span: 6 }}>
                  {getFieldDecorator('contactName')(<Input placeholder="请输入联系人姓名" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="手机号码" labelCol={{ span: 6 }}>
                  {getFieldDecorator('contactMobile')(<Input placeholder="请输入手机号码" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="邮箱" labelCol={{ span: 6 }}>
                  {getFieldDecorator('contactMail')(<Input placeholder="请输入邮箱" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="关联客服" labelCol={{ span: 6 }}>
                  {getFieldDecorator('run')(<Input placeholder="请输入关联客服" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="关联销售" labelCol={{ span: 6 }}>
                  {getFieldDecorator('sale')(<Input placeholder="请输入关联销售" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="创建日期" labelCol={{ span: 6 }}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="认证号码" labelCol={{ span: 6 }}>
                  {getFieldDecorator('verifyNumber')(<Input placeholder="请输入认证号码" />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={(e) => onChangeGetFilter(e)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) =>
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-customer">
        {
          authList.includes("vendor-id-query") ||
            authList.includes("customer-name-query") ||
            authList.includes("distribution-manager") ||
            authList.includes("distribution-sales") ||
            authList.includes("distribution-customer-service") ?
            <Dropdown overlay={menu}>
              <Button>
                更多操作 <Icon type="down" />
              </Button>
            </Dropdown> : null
        }
        <Auth auths={authList} code="negative-interest-repayment">
          <Button type="primary" className="add-customerBtn" onClick={onPayback}>负利还款</Button>
        </Auth>
        <Auth auths={authList} code="add-customer">
          <Link to={`${match.path}/addCustomer`}>
            <Button className="add-customerBtn" type="primary">新增客户</Button>
          </Link>
        </Auth>
        <Auth auths={authList} code="batch-transmit-customer">
          <Button className="add-customerBtn" type="primary" onClick={onBatchTransfer}>批量转户</Button>
        </Auth>
        <Auth auths={authList} code="repair-manufacturer-info">
          <Button className="add-customerBtn" type="primary" onClick={onRepairManufacturer}>厂商信息修补</Button>
        </Auth>
        <Auth auths={authList} code="batch-user-vendor-id-transfer">
          <Button className="add-customerBtn" type="primary" onClick={() => history.push('/csr/customer/newVendor', { muilt: 2 })}>批量客户厂商ID转移</Button>
        </Auth>
      </div>
      <CsrManagementRepair
        setVisibleRepair={setVisibleRepair}
        visibleRepair={visibleRepair}
        getList={getList}
      />
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        // scroll={{ x: 2200 }}
        onChange={onChangeTable}
      >
        <Column title="OAID" dataIndex="id" />
        <Column title="OA客户名称"
          dataIndex="name"
          width={260}
          ellipsis={true}
          render={(name, record) => <a rel="noopener noreferrer" onClick={() => {
            history.push(`${match.path}/detail?id=${record.id}&new=1&status=${record.status}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`)
          }}>{name}</a>}
        />
        <Column title="认证状态" dataIndex="statusName" />
        <Column title="账户总余额"
          dataIndex="remainMoney"
          render={(name, record) => <Link to={`/account?customerId=${record.id}&relationType=${0}`}>{name}</Link>}
        />
        <Column title="总欠款金额"
          dataIndex="debtMoney"
          render={(name, record) => <Link to={`/account/credit?id=${record.id}&type=${0}`}>{name}</Link>}
        />
        <Column title="厂商ID个数" dataIndex="firmNum" />
        <Column title="创建时间" dataIndex="addTime" />
        <Column title="操作" key="set" width={100} render={(text, record) => (
          <>
            {
              <Tooltip title="在新页面打开详情页" placement="bottom">
                <Link target='_blank' to={`${match.path}/detail?id=${record.id}&status=${record.status}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>
                  <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '8px' }} />
                </Link>
              </Tooltip>
            }
            {
              <Tooltip title="编辑" placement="bottom">
                <Link to={`${match.path}/customerChange?oaId=${record.id}`}>
                  <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" />
                </Link>
              </Tooltip>
            }
          </>
        )} />
      </Table>
      <Drawer
        title="厂商ID查询"
        width={880}
        onClose={() => { setVendorVisible(false) }}
        visible={vendorVisible}
        destroyOnClose={true}
      >
        <Spin spinning={vendorloading}>
          <VendorIdQuery
            setVendorVisible={setVendorVisible}
            setVendorLoading={setVendorLoading}
          />
        </Spin>
      </Drawer>
      <Drawer
        title="客户名称查询"
        width={880}
        onClose={() => { setNameVisible(false) }}
        visible={nameVisible}
        destroyOnClose={true}
      >
        <Spin spinning={nameloading}>
          <NameQuery
            setNameVisible={setNameVisible}
            setNameLoading={setNameLoading}
          />
        </Spin>
      </Drawer>
      <Drawer
        title={drawerName}
        width={800}
        onClose={() => setPaybackVisible(false)}
        visible={paybackVisible}
        destroyOnClose={true}
      >
        <PaybackList drawerName={drawerName} history={history}></PaybackList>
      </Drawer>
      <Drawer
        title="转户申请"
        width={880}
        onClose={() => setTransferVisible(false)}
        visible={transferVisible}
        destroyOnClose={true}
      >
        <Spin spinning={transferLoading}>
          <BatchTransfer
            setTransferVisible={setTransferVisible}
            setTransferLoading={setTransferLoading} />
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(CsrManagement)