/**
 * 模块名称: 公司管理
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  InputNumber,
  message,
  Icon,
  Popconfirm,
  Cascader,
  Switch,
  Tooltip,
  Spin
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import Upload from '@/components/Upload'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import Coltype from './module/Coltype'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

const Corp = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [prefix, setPrefix] = useState('http://')
  const [options, setOptions] = useState([])
  const [authList, setAuthList] = useState([])
  const [projects, setProjects] = useState([])
  const search = parseSearch(location.search)
  const [typeList, setTypeList] = useState([])
	const [type, setType] = useState(undefined)
  const [drawerLoading, setDrawerLoading] = useState(false)
  const [drawerName, setDrawerName] = useState('添加公司')
  const [coltypeVisible, setColtypeVisible] = useState(false)

  const loadProjects = () => {
    api.getTopProjectList({ isOpen: 1 }).then(data => {
      setProjects(data.list)
    }).catch(() => setLoading(false))
  }

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    getCorpList()
    api.getPageAuth().then(list => setAuthList(list))
    setCorpTypeList()
    loadProjects()
  }, [])

  //设置公司类型
  const setCorpTypeList = () => setTypeList([{id: 1, name: '总公司'}, {id: 2, name: '分公司'}, {id: 3, name: '子公司'}])

  const getCorpList = () => {
    setLoading(true)
    api.getCorp({ limit: pageSize, page: currentPage }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 添加公司
  const formSubmit = evt => {
    evt.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (vals.website) {
          vals.website = prefix + vals.website
        }
        if (editId !== '') {
          vals.id = editId
        }
        if (vals.area) {
          vals.area = vals.area[1]
        }
        setSaveLoading(true)
        api.saveCompany(vals).then(() => {
          setSaveLoading(false)
          setVisible(false)
          message.success('保存成功')
          getCorpList(currentPage)
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const onDelete = (id) => {
    api.delCompany({ id }, true).then(() => {
      message.success('删除成功')
      getCorpList()
    })
  }

  const onEdit = (corp) => {
    // console.log(corp)
    setVisible(true)
    setDrawerLoading(true)
    editId = corp.id
    setDrawerName(`编辑${corp.typeCn}`)
    const timer = setTimeout(() => {
      clearTimeout(timer)
      setDrawerLoading(false)
      if (corp.area.length) {
        Promise.all([
          api.getArea({ pid: 0 }),
          api.getArea({ pid: corp.area[0] })
        ]).then(list => {
          for (let i = 0; i < list[0].length; i++) {
            if (list[0][i].id === corp.area[0]) {
              list[0][i].children = list[1]
              break
            }
          }
          setOptions(list[0].map(item => ({...item, isLeaf: item.depth !== '0'})))
          setFieldsValue({ area: corp.area })
        })
      } else {
        getAreaOptions(0)
        setFieldsValue({ area: []})
      }
      const projectRangeDefVals = corp.projectRangeList.map((project) => project.id)
      setFieldsValue({
        name: corp.name,
        type: corp.type,
        fullName: corp.fullName,
        bankName: corp.bankName,
        bankNo: corp.bankNo,
        yejidate: corp.yejidate,
        address: corp.address,
        tel: corp.tel,
        fax: corp.fax,
        website: corp.website.substring(corp.website.indexOf('//') + 2),
        zipcode: corp.zipcode,
        logo: corp.logo,
        projectRange: projectRangeDefVals,
        accountPre: corp.accountPre
      })
    }, 1000)
  }

  // 添加公司
  const onAddCorp = () => {
    editId = ''
    setDrawerName('添加公司')
    setVisible(true)
    setPrefix('http://')
    resetFields()
    getAreaOptions(0)
  }

  const getAreaOptions = pid => {
    api.getArea({ pid }).then(options => {
      setOptions(options.map(item => ({ ...item, isLeaf: item.depth !== '0' })))
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}/corp?page=${currentPage}&pageSize=${pageSize}`)
    getCorpList()
  }

  const loadOptions = (option) => {
    const targetOption = option[option.length - 1]
    targetOption.loading = true
    api.getArea({ pid: targetOption.id }).then(children => {
      targetOption.loading = false
      targetOption.children = children
      setOptions(Object.assign([], options))
    })
  }

  const onChangeStatus = (id, val) => {
    api.updateIsValid({id, isValid: Number(val)})
  }

  const onSort = (id, flow) => {
    api.updateCompanySort({ id, flow }).then(getCorpList)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const fitleSpace = evt => {
    const { value,id } = evt.target;
    const input = evt.target

    if(/\s+/.test(value)){
      let pos = {
        start: 0,
        end: 0
      };

      if(evt.target.selectionStart){
        pos.start = evt.target.selectionStart
      }

      pos.end = evt.target.selectionEnd

      const newVal = {};
      newVal[id] = value.replace(/\s+/g,"");
      setFieldsValue(newVal, () => {
        pos.end = pos.end - 1;
        input.setSelectionRange(pos.start, pos.end)
      })
    }

  }

  const selectBefore = (
    <Select value={prefix} style={{ width: 90 }} onChange={val => setPrefix(val)}>
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  )

  return (
    <>
      <div className="line"></div>
      <div className="add-wrap">
        <Button type="primary" onClick={() => setColtypeVisible(true)}>收款方式</Button>
        <Auth auths={authList} code="add">
          <Button type="primary" icon="plus" onClick={onAddCorp} style={{ marginLeft: 15 }}>添加</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 2600}}
        // scroll={{x: 'max-content'}}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" fixed="left" width={100}/>
        <Column title="公司主体（简称）" dataIndex="name" fixed="left" width={150} />
        <Column title="公司类型" dataIndex="typeCn" />
        <Column title="所在地" dataIndex="areaCn" />
        <Column title="总机" dataIndex="tel" />
        <Column title="传真" dataIndex="fax" />
        <Column title="网址" key="website" render={text => <a href={text.website} target="_blank" rel="noopener noreferrer">{text.website}</a>} />
        <Column title="地址" dataIndex="address" width={220} />
        <Column title="邮编" dataIndex="zipcode" />
        <Column title="业绩计算日期" dataIndex="yejidate" />
        <Column title="业务范围" dataIndex="projectRangeCn" width={380} />
        <Column title="排序" key="sort" render={(text, record, index) => (
          <span className="caret-wrap">
            <Icon type="caret-up" onClick={() => onSort(text.id, 'up')} />
            <Icon type="caret-down" onClick={() => onSort(text.id, 'down')} />
          </span>
        ) } />
        <Column title="有效" key="isValidCn" render={(text, record) => <Switch defaultChecked={text.isValid === 1} onChange={val => onChangeStatus(text.id, val)} />} />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Auth auths={text.operateAuth} code="edit">
              <Tooltip title="编辑" placement="bottom">
                <Icon type="edit" className="operate-icon" style={{ color: '#1890ff' }} onClick={() => onEdit(record)} />
              </Tooltip>
            </Auth>
            {' '}
            <Auth auths={text.operateAuth} code="delete">
              <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Tooltip title="删除" placement="bottom">
                  <Icon type="delete" className="operate-icon" style={{ color: 'red' }} />
                </Tooltip>
              </Popconfirm>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title={drawerName}
        width={600}
        onClose={() => {
          setVisible(false)
          setDrawerName('添加公司')
        }}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={drawerLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="公司主体（简称）">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入公司主体（简称）' }]
              })(<Input placeholder="请输入公司主体（简称）" />)}
            </FormItem>
            <FormItem label="公司所在地">
              {getFieldDecorator('area')(
                <Cascader
                  fieldNames={{ label: 'cityName', value: 'id' }}
                  options={options}
                  loadData={loadOptions}
                  onChange={null}
                  changeOnSelect
                  placeholder="请选择公司所在地"
                />)}
            </FormItem>
            <FormItem label="公司全称">
              {getFieldDecorator('fullName', {
                rules: [{ required: true, message: '请输入公司全称' }]
              })(<Input placeholder="请输入公司全称" />)}
            </FormItem>
            <FormItem label="开户银行">
            {getFieldDecorator('bankName', {
              rules:[{
                required: false,
                pattern: new RegExp(/^[\u4e00-\u9fa5|a-zA-Z|（）|0-9| ]{0,50}$/, "g"),
                message: '开户银行只支持汉字、数字、字母、全角括号，最多不超过50个字符',
              }]
            })(<Input placeholder="请输入开户银行" onKeyUp={fitleSpace} />)}
            </FormItem>
            <FormItem label="账号">
              {getFieldDecorator('bankNo', {
                rules:[{
                  required: false
                }]
              })(<Input placeholder="请输入账号" onKeyUp={fitleSpace} />)}
            </FormItem>
            <FormItem label="总机号">
              {getFieldDecorator('tel', {
                rules:[{
                  required: false,
                  pattern: new RegExp(/^[0-9-]+$/, "g"),
                  message: '总机号只允许输入数字、-'
                }]
              })(<Input placeholder="请输入总机号" />)}
            </FormItem>
            <FormItem label="传真号">
              {getFieldDecorator('fax', {
                rules:[{
                  required: false,
                  pattern: new RegExp(/^[0-9-]+$/, "g"),
                  message: '传真号只允许输入数字、-'
                }]
              })(<Input placeholder="请输入传真号"/>)}
            </FormItem>
            <FormItem label="网址">
              {getFieldDecorator('website')(<Input addonBefore={selectBefore} placeholder="请输入公司网址" />)}
            </FormItem>
            <FormItem label="公司地址">
              {getFieldDecorator('address')(<Input.TextArea placeholder="请输入公司地址" />)}
            </FormItem>
            <FormItem label="邮编">
              {getFieldDecorator('zipcode')(<Input placeholder="请输入邮政编码" />)}
            </FormItem>
            <FormItem label="公司类型">
              {getFieldDecorator('type', {initialValue: type,
                rules: [{ required: true, message: '请选择公司类型' }]
              })(
                <Select placeholder="请选择公司类型">
                  {/* <Option value="1">总公司</Option>
                  <Option value="2">分公司</Option>
                  <Option value="3">子公司</Option> */}
                  {
                    typeList.map((item, index) => {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label={<Tooltip title="每月业绩从该日期起计算，至下个月该日期截止" placement="topLeft">业绩月份计算日期</Tooltip>}>
              {getFieldDecorator('yejidate', { initialValue: 3, rules: [{ required: true, message: '请选择公司类型' }] })(<InputNumber min={1} max={28} />)}
            </FormItem>
            <FormItem label="项目范围">
              {getFieldDecorator('projectRange')(
                  <Select
                      showSearch
                      mode="multiple"
                      placeholder="请选择项目范围"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                  >
                    {projects.map(project => <Option key={project.id}>{project.name}</Option>)}
                  </Select>
                  )}
            </FormItem>
            <FormItem label="LOGO">
              {getFieldDecorator('logo')(<Upload data={{ max: 100, allowExts: 'jpg,jpeg,png' }} />)}
            </FormItem>
            <FormItem label="账户前缀">
              {getFieldDecorator('accountPre', {
                rules:[{
                  required: false,
                  pattern: new RegExp(/[a-zA-Z]$/, "g"),
                  message: '账户前缀只允许输入英文'
                }]
              })(<Input maxLength={10 } placeholder="请输入账户前缀" />)}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        title="设置订单收款方式"
        width={600}
        onClose={() => setColtypeVisible(false)}
        visible={coltypeVisible}
        destroyOnClose={true}
      >
        <Coltype setColtypeVisible={setColtypeVisible} />
      </Drawer>
    </>
  )
}

export default Form.create()(Corp)