/**
 * 模块名称: 转户详情与审批详情页
 */

import React, { useState, useEffect } from 'react'
import {
    Descriptions,
    Steps,
    Button,
    Modal,
    Input,
    Table,
    Icon,
    Alert,
    message,
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
// let pageSize = 5
let pageSizeC = 10
let pageSizeI = 10
let pageSizeA = 10
let pageSizeS = 10

let currentPageC = 1
let currentPageI = 1
let currentPageA = 1
let currentPageS = 1


const AddCustomerDetail = (props) => {
    const { match, location, history } = props
    const [delay, ] = useState(3000)
    const [detailData, setDetailData] = useState({})
    const [detailDataId, setdetailDataId] = useState('')
    const [statusList, setStatusList] = useState([])
    const [logConList, setLogConList] = useState([])
    const [buttonAuthList, setButtonAuthList] = useState([])
    const [passModalVisible, setModalVisible] = useState(false) //确认弹框
    const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
    const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
    const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
    const [pasDesc, setPasDesc] = useState('')  //通过意见
    const [showFile, setShowFile] = useState([]) //附件

    const [clientList, setClientList] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [acBalance, setAcBalance] =useState([])
    const [acArrears, setAcArrears] =useState([])

    const [countC, setClientC] = useState(0)
    const [countI, setCountI] = useState(0)
    const [countA, setCountA] = useState(0)
    const [countS, setCountS] = useState(0)
    const [outIdVal, setOutIdVal] = useState('')
    const [reviewLoading, setReviewLoading] = useState(false) // 审批确定loading

    const search = parseSearch(location.search)

    useEffect(() => {
        let plaintext = search.sign
        let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
    
        if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
            pageSizeC = 10
            pageSizeI = 10
            pageSizeA = 10
            pageSizeS = 10
    
            currentPageC = 1
            currentPageI = 1
            currentPageA = 1
            currentPageS = 1
            api.getTransferDetail({ apid: search.id, type: 0 }, true).then(data => {
                if (search.msgId) {
                    api.setMessageReaded({ ids: [search.msgId] })
                }
                setDetailData(data)
                setdetailDataId(data.id)
                setStatusList(data.audit_flow) // 审批流
                setLogConList(data.audit_log) // 审批记录表
                setButtonAuthList(data.auditAuth) // 审批权限
                setShowFile(JSON.parse(data.attachmentUrl)) //附件
    
                getClientList(data.oa_qd_ids, data.outId)
                getInvoiceList(data.oa_qd_ids, data.outId)
                getAccountList(data.oa_qd_ids, data.outId, data.no)
                getAcArrearsList(data.oa_qd_ids, data.outId, data.no)
                setOutIdVal(data.outId)
            })
        } else {
          message.error('没有本页访问权限')
          setTimeout(() => {
            history.go(-2)
          }, 1000)
        }
    }, [])

    //客户信息
    const getClientList = (idVal, outId) => {
        api.getClientInfo({ ids: detailData.oa_qd_ids ? detailData.oa_qd_ids : idVal, outId, getQuery: { limit: pageSizeC, page: currentPageC } }).then(data => {
            setClientList(data.list)
            setClientC(data.count)
        })
    }
    const onChangeClientTable = (pagination, filters, sorter) => {
        currentPageC = pagination.current
        pageSizeC = pagination.pageSize
        // history.replace(match.path + '?page=' + currentPageC + '&limit=' + pageSizeC)
        getClientList(detailData.oa_qd_ids, outIdVal)
    }


    //未收款发票
    const getInvoiceList = (idVal, outId) => {
        api.getInvoiceInfo({ ids: detailData.oa_qd_ids ? detailData.oa_qd_ids : idVal, outId, getQuery: { limit: pageSizeI, page: currentPageI } }).then(data => {
            setInvoiceList(data.list)
            setCountI(data.count)
        })
    }
    const onChangeInvoiceTable = (pagination, filters, sorter) => {
        currentPageI = pagination.current
        pageSizeI = pagination.pageSize
        // history.replace(match.path + '?page=' + currentPageI + '&limit=' + pageSizeI)
        getInvoiceList(detailData.oa_qd_ids, outIdVal)
    }

    //帐户余额
    const getAccountList = (idVal, outId, no) => {
        api.getAccountInfo({ clientIds: detailData.oa_qd_ids ? detailData.oa_qd_ids : idVal, outId, no: no, getQuery: { limit: pageSizeA, page: currentPageA } }).then(data => {
            setAcBalance(data.list)
            setCountA(data.count)
        })
    }
    const onChangeAccountTable = (pagination, filters, sorter) => {
        currentPageA = pagination.current
        pageSizeA = pagination.pageSize
        // history.replace(match.path + '?page=' + currentPageA + '&limit=' + pageSize)
        getAccountList(detailData.oa_qd_ids, outIdVal, detailData.no)
    }


    //信用帐户欠款
    const getAcArrearsList = (idVal, outId, no) => {
        api.getAcArrearsInfo({ clientIds: detailData.oa_qd_ids ? detailData.oa_qd_ids : idVal, outId, no: no, getQuery: { limit: pageSizeS, page: currentPageS } }).then(data => {
            setAcArrears(data.list)
            setCountS(data.count)
        })
    }
    const onChangeAcArrearsTable = (pagination, filters, sorter) => {
        currentPageS = pagination.current
        pageSizeS = pagination.pageSize
        // history.replace(match.path + '?page=' + currentPageS + '&limit=' + pageSizeS)
        getAcArrearsList(detailData.oa_qd_ids, outIdVal, detailData.no)
    }

    // 通过
    const onBtnPassShow = () => {
        setModalVisible(true)
    }

    const onPassDescChange = (e) => {
        setPasDesc(e.target.value)
    }
    //通过
    const onApprove = () => {
        setReviewLoading(true)
        api.operationApproval({
            id: detailDataId,
            type: 0,
            remark: pasDesc
        }).then(data => {
            message.success('操作成功')
            setReviewLoading(false)
            setModalVisible(false)
            // api.getTransferDetail({ apid: search.id, type: 0 }, true).then(data => {
            //     setDetailData(data)
            //     setdetailDataId(data.id)
            //     setStatusList(data.audit_flow) // 审批流
            //     setLogConList(data.audit_log) // 审批记录表
            //     setButtonAuthList(data.auditAuth) // 审批权限
            //     setShowFile(JSON.parse(data.attachmentUrl)) //附件
            // }) //确认通过后
            window.location.reload()
        }).catch(() => {
            setReviewLoading(false)
            setModalVisible(false)
        })
    }

    // 驳回
    const onBtnTurnShow = () => {
        setTurnModalVisible(true)
        setTextDisplay(true)
    }

    //驳回
    const onTurnApprove = () => {
        if (buttonAuthList.reject_msg_require) {
            if (textAreaVal.trim() === '') {
                setTextDisplay(false)
                return
            }
        }
        setReviewLoading(true)
        api.operationApproval({
            id: detailDataId,
            type: 1,
            remark: textAreaVal
        }).then(data => {
            // api.operationApproval({ id: search.id, type: 0 }, true).then(data => {
            //     setDetailData(data)
            //     setdetailDataId(data.id)
            //     setStatusList(data.audit_flow) // 审批流
            //     setLogConList(data.audit_log) // 审批记录表
            //     setButtonAuthList(data.auditAuth) // 审批权限
            //     setShowFile(JSON.parse(data.attachmentUrl)) //附件
            // }) //确认驳回后
            setReviewLoading(false)
            message.success('操作成功')
            window.location.reload()
        }).catch(() => setReviewLoading(false))
        setTurnModalVisible(false)
    }

    const onDescChange = (e) => {
        setTextAreaVal(e.target.value)
        setTextDisplay(true)
    }

    // 驳回样式
    const turnNode = () => {
        return <>
            确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
        </>
    }

    // 同意样式
    const agreeNode = () => {
        return <>
            确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
        </>
    }

    // 转上级
    const onTurnUp = () => {
        api.operationApproval({
            id: detailDataId,
            type: 2,
            remark: ''
        }).then(data => {
            // history.push('/csr/turnAudit/detail')
            message.success('操作成功')
            window.location.reload()
        }).catch()
    }

    // // 补充资料
    // const onReplenish = () => {
    //     api.operationApproval({
    //         id: detailDataId,
    //         type: 3,
    //         remark: ''
    //     }).then(data => {
    //         history.push('/csr/addAudit')
    //     }).catch()
    // }

    return (
        <>
            <Descriptions title="转户审批详情" layout="horizontal " className="pro-detail-title">
                <dItem label="转出业务编号">
                    <> {detailData.no}</>
                </dItem>
            </Descriptions>
            <div className="line-box"></div>

            <div className="pro-detail">
                <Descriptions title="审批进度" className="pro-detail-smailtitle"></Descriptions>
                <div className="promotionProcess" >
                    <Steps>
                        {
                            statusList.map((item, index) => {
                                let status = 'wait'
                                if (item.time && item.time.length > 1) {
                                    status = 'process'
                                }
                                return <Step status={status}
                                    key={index}
                                    value={item.time}
                                    title={item.nodeName}
                                    description={
                                        <>
                                            {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                                            <div title={item.statusName}>{item.statusName}</div>
                                            <div title={item.time}>{item.time}</div>
                                        </>
                                        // `${item.persons.map((item)=>item)} ${item.statusName} ${item.time}`
                                    }
                                />
                            })
                        }
                    </Steps>
                </div>
                <div className="line-box"></div>
                <Descriptions title="员工信息"
                    layout="vertical"
                    className="pro-detail-smailtitle pro-apply"
                    column={4}
                >
                    <dItem label="转出人">
                        {detailData.outName}
                    </dItem>
                    <dItem label="员工类型">
                        {detailData.outTransferType}
                    </dItem>
                    <dItem label="直属上级">
                        {detailData.outLeader}
                    </dItem>
                    <dItem label="所属部门">
                        {detailData.outDepartment}
                    </dItem>
                    <dItem label="转入人">
                        {detailData.inName}
                    </dItem>
                    <dItem label="员工类型">
                        {detailData.transferTypeName}
                    </dItem>
                    <dItem label="直属上级">
                        {detailData.inLeader}
                    </dItem>
                    <dItem label="所属部门">
                        {detailData.inDepartment}
                    </dItem>
                </Descriptions>
                <div className="line-box"></div>
                <Descriptions
                    title="客户信息"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countC}条数据`} type="info" showIcon />
                <Table
                    dataSource={clientList}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeC,
                        total: countC,
                        current: currentPageC,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeClientTable}
                >
                    <Column title="客户名称" dataIndex="name" />
                    <Column title="OAID" dataIndex="id" />
                    <Column title="创建时间" dataIndex="addTime" />
                    <Column title="帐户总余额" dataIndex="remainMoney" />
                    <Column title="信用帐户欠款" dataIndex="debtMoney" />
                </Table>

                <Descriptions
                    title="未收款发票"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countI}条数据`} type="info" showIcon />
                <Table
                    dataSource={invoiceList}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeI,
                        total: countI,
                        current: currentPageI,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeInvoiceTable}
                    scroll={{ x: 1400 }}
                >
                    <Column title="OA客户名称" dataIndex="customerName" />
                    <Column title="OAID" dataIndex="customerId" />
                    <Column title="发票抬头" dataIndex="invtitle" />
                    <Column title="发票金额" dataIndex="money" />
                    <Column title="发票类型" dataIndex="invtypeName" />
                    <Column title="发票号" dataIndex="invoiceNumber" />
                    <Column title="发票状态" dataIndex="InvoiceStatusName" />
                    <Column title="收款状态" dataIndex="collestatusName" />
                    <Column title="申请人" dataIndex="applyName" />
                    <Column title="申请时间" dataIndex="applyDate" />
                </Table>
                <Descriptions
                    title="帐户余额"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countA}条数据`} type="info" showIcon />
                <Table
                    dataSource={acBalance}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeA,
                        total: countA,
                        current: currentPageA,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeAccountTable}
                >
                    <Column title="帐户编号" dataIndex="accountNo" />
                    <Column title="OAID" dataIndex="relationId" />
                    <Column title="OA客户名称" dataIndex="name" />
                    <Column title="	帐户" dataIndex="companyName" />
                    <Column title="创建时间" dataIndex="addTime" />
                    <Column title="帐户余额" dataIndex="balance" />
                </Table>
                <Descriptions
                    title="信用帐户欠款"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Alert className="corp-count" message={`总计：${countS}条数据`} type="info" showIcon />
                <Table
                    dataSource={acArrears}
                    rowKey="id"
                    className="table-page"
                    // loading={loading}
                    pagination={{
                        pageSize: pageSizeS,
                        total: countS,
                        current: currentPageS,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeAcArrearsTable}
                >
                    <Column title="OAID" dataIndex="oaqId" />
                    <Column title="客户名称" dataIndex="clientName" />
                    <Column title="帐户" dataIndex="company" />
                    <Column title="帐户类型" dataIndex="accountTypeName" />
                    <Column title="已用额度" dataIndex="usedAmount" />
                    <Column title="到期还款日" dataIndex="endDay" />
                    <Column title="申请人" dataIndex="proposerName" />
                </Table>
                <div className="line-box"></div>
                <Descriptions title="其他信息"
                    layout="vertical"
                    className="pro-detail-smailtitle pro-apply"
                >
                    <dItem label="附件" span={3}>
                        <>
                            {
                                 showFile && showFile.length >= 1 ? showFile.map((item, i) => {
                                    return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                                }) : '无'
                            }
                        </>
                    </dItem>
                    <dItem label="备注">
                        <>
                            {detailData.remark ? detailData.remark : '无'}
                        </>
                    </dItem>
                </Descriptions>
        
                {/* 审批记录 */}
                <div className="line-box"></div>
                <Descriptions
                    title="审批记录"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Table
                    dataSource={logConList}
                    rowKey="nodeName"
                    pagination={false}
                >
                    <Column title="审批人" dataIndex="userInfo.staffName" />
                    <Column title="审批时间" dataIndex="addTime" />
                    <Column title="审批状态" dataIndex="status" />
                    <Column title="审批意见" dataIndex="chargeMsg" />
                </Table>
                <div className="btn-setting">
                    {/* <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button> */}
                    <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
                    <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
                    <Modal
                        // title="确认驳回"
                        title={turnNode()}
                        visible={turnModalVisible}
                        onOk={debounce(() => {onTurnApprove()}, delay)}
                        onCancel={() => setTurnModalVisible(false)}
                        footer={[
                            <Button key="back" onClick={() => setTurnModalVisible(false)}>取消</Button>,
                            <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {onTurnApprove()}, delay)}>确定</Button>
                        ]}
                    >
                        <div>
                            <span>审批意见:</span>
                            <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
                        </div>
                        <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
                    </Modal>
                    <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
                    <Modal
                        // title="确认通过"
                        title={agreeNode()}
                        visible={passModalVisible}
                        onOk={debounce(() => {onApprove()}, delay)}
                        onCancel={() => setModalVisible(false)}
                        footer={[
                            <Button key="back" onClick={() => setModalVisible(false)}>取消</Button>,
                            <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {onApprove()}, delay)}>确定</Button>
                        ]}
                    >
                        <div>
                            <span>审批意见:</span>
                            <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default AddCustomerDetail