/**
 * 模块名称: 客户认证(新增)审批
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Alert,
  Switch
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
  corpId: '',
  oaId: '',
  name: '',
  verifyType: '',
  verifyNumber: '',
  auditStatus: '',
  creator: '',
  sTime: '',
  eTime: '',
}

const AddCustomerAudit = (props) => {
  const { match, history } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [auditStatusAll, setAuditStatusAll] = useState([]) // 审批状态
  const [verifyTypeAll, setVerifyTypeAll] = useState([]) // 认证类型
  // const [authList, setAuthList] = useState([])
  const search = parseSearch(props.location.search)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      corpId: '',
      oaId: '',
      name: '',
      verifyType: '',
      verifyNumber: '',
      auditStatus: '',
      creator: '',
      sTime: '',
      eTime: '',
    }
    shouldMe = null
    apiOperateTimer = true
    getList()
    getFilterData()
    // api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(shouldMe){
        filter.shouldMe = shouldMe
      }else{
        for(let i in filter){
          if(i == 'shouldMe')delete filter[i]
        }
      }
      api.getClientVerifyList({ ...filter, limit: pageSize, page: currentPage }).then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getList()
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          sTime: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          eTime: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : '',
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    shouldMe = null
    filter = {
      corpId: filter.corpId,
      oaId: '',
      name: '',
      verifyType: '',
      verifyNumber: '',
      auditStatus: '',
      creator: '',
      sTime: '',
      eTime: '',
    }
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 筛选条件
  const getFilterData = () => {
    api.getAuditStatusList({ type: '14'}).then(res => setAuditStatusAll(res))
    api.getClientVerifyFilterData().then(data => {
      setVerifyTypeAll(data.verifyType)
    })
  }

  // 删除
  const onDelete = (id) => {
    api.delClientVerifyDel({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    }
  }

  return (
    <div className="table-public">
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        {/* <h4>其他选项: </h4> */}
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="OAID" labelCol={{ span: 10 }}>
                  {getFieldDecorator('oaId')(<Input placeholder="请输入OAID" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="客户名称" labelCol={{ span: 8 }}>
                  {getFieldDecorator('name')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="真实性验证号码" labelCol={{ span: 10 }}>
                  {getFieldDecorator('verifyNumber')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="真实性验证号码类型" labelCol={{ span: 10 }}>
                  {getFieldDecorator('verifyType')(
                    <Select placeholder="全部">
                      {verifyTypeAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="审批状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('auditStatus')(
                    <Select placeholder="全部" showSearch={true} optionFilterProp="children">
                      {auditStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="创建人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('creator')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="申请时间" labelCol={{ span: 10 }}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12} style={{ width: '140%' }} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="recruit-search-btns">
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        {/* <Auth auths={authList} code="staff-attendance-apply"> */}
        <Link to={`/csr/customer/addCustomer`}>
          <Button type="primary">新增客户</Button>
        </Link>
        {/* </Auth> */}
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="OAID"
          dataIndex="clientId"
          fixed="left"
          width={150}
          render={(clientId, record) => <>{clientId === 0 ? '' : clientId}</>}
        />
        <Column title="客户名称"
          dataIndex="name"
          width={240}
          ellipsis={true}
          fixed="left"
          render={(name, record) => <Link to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{name}</Link>}
        />
        <Column title="真实性验证号码类型" dataIndex="verifyTypeName" />
        <Column title="真实性验证号码" dataIndex="verifyNumber" />
        <Column title="创建人" dataIndex="creatorName" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="审批状态" dataIndex="auditStatusName" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {
              record.editFlag ?
                <Tooltip title="编辑" placement="bottom">
                  {
                    record.clientId !== 0 ?
                      <Link to={`/csr/customer/editCustomer?clientId=${record.clientId}&id=${record.id}&edit=${1}`}>
                        <Icon className="operate-icon" style={{ color: '#1890FF' }} type="edit" />
                      </Link> :
                      <Link to={`/csr/customer/editCustomer?id=${record.id}&edit=${1}`}>
                        <Icon className="operate-icon" style={{ color: '#1890FF' }} type="edit" />
                      </Link>
                  }
                </Tooltip>
                : null
            }
            {' '}
            {
              record.delFlag ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }
          </>
        )} />
      </Table>
    </div>
  )
}

export default Form.create()(AddCustomerAudit)
